$static_path: "./../../";

@font-face {
    font-family: "ProximaNova-Regular";
    src: url("../fonts/new/proximanova-regular-webfont.eot");
    src: url('../fonts/new/proximanova-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/new/proximanova-regular-webfont.woff2') format('woff2'),
         url('../fonts/new/proximanova-regular-webfont.woff') format('woff'),
         url('../fonts/new/proximanova-regular-webfont.ttf') format('truetype'),
         url('../fonts/new/proximanova-regular-webfont.svg#proxima_nova_rgregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'ProximaNova-Semibold';
    src: url('../fonts/new/proximanova-semibold-webfont.eot');
    src: url('../fonts/new/proximanova-semibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/new/proximanova-semibold-webfont.woff2') format('woff2'),
         url('../fonts/new/proximanova-semibold-webfont.woff') format('woff'),
         url('../fonts/new/proximanova-semibold-webfont.ttf') format('truetype'),
         url('../fonts/new/proximanova-semibold-webfont.svg#proxima_novasemibold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
  font-family: 'ProximaNova-Bold';
  src: url('../fonts/new/proxima_nova_bold-webfont.eot');
  src: url('../fonts/new/proxima_nova_bold-webfont.eot?#iefix') format('embedded-opentype'),
       url('../fonts/new/proxima_nova_bold-webfont.woff2') format('woff2'),
       url('../fonts/new/proxima_nova_bold-webfont.woff') format('woff'),
       url('../fonts/new/proxima_nova_bold-webfont.svg#proxima_novabold') format('svg');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  src: url('../fonts/material-icons.woff2') format('woff2');
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
}

// Font Family Roboto mono regular
@font-face {
    font-family: 'roboto_mono';
    src: url('../fonts/robotomono-regular-webfont.eot');
    src: url('../fonts/robotomono-regular-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/robotomono-regular-webfont.woff2') format('woff2'),
    url('../fonts/robotomono-regular-webfont.woff') format('woff'),
    url('../fonts/robotomono-regular-webfont.svg#roboto_monoregular') format('svg');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'roboto_mono';
    src: url('../fonts/robotomono-italic-webfont.eot');
    src: url('../fonts/robotomono-italic-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/robotomono-italic-webfont.woff2') format('woff2'),
    url('../fonts/robotomono-italic-webfont.woff') format('woff'),
    url('../fonts/robotomono-italic-webfont.svg#roboto_monoitalic') format('svg');
    font-weight: 400;
    font-style: italic;
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}
