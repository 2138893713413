$static_path_modal: '../../';

.react-modal-overlay {
  &.add-new-company {
    background-color: $gray_light_v2;
    width: 100%;
    height: 100vh;
    padding: 54px 54px 54px 54px;
    align-items: flex-start;
  }
}

.react-modal {
  &.modal-new-company{
    width: 960px;
    background-color: $tr;
  }
}

.modal-new-company-block-body {
  .payment-introduction .payment-introduction-item span.status {
    display: inline-block;
  }

  .modal-new-company-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;

    .logo {
      margin: 0;
    }

    .close-new-company-modal {
      width: 48px;
      height: 48px;
      display: block;
      cursor: pointer;

      img {
        width: 100%;
        display: block;
      }
    }
  }

  .modal-new-company-steps-block {
    display: flex;
    justify-content: space-between;
    margin-bottom: 28px;

    .modal-new-company-step {
      padding: 16px 10px 18px 26px;
      
      &:nth-child(1) {
        .inside-step {
          span {
            padding-top: 5px;
          }
        }
      }
      &.step-active {
        .inside-step {
          span {
            color: $dark_blue;
          }
        }

        &:nth-child(1) {
          background: url($static_path_modal + 'img/company-step-1-active.svg') center center no-repeat;
          width: 250px;
        }

        &:nth-child(2) {
          background: url($static_path_modal + 'img/company-step-2-active.svg') center center no-repeat;
          width: 240px;
          padding: 19px 10px 18px 54px;
        }

        &:nth-child(3) {
          background: url($static_path_modal + 'img/company-step-2-active.svg') center center no-repeat;
          width: 240px;
          padding: 19px 10px 18px 54px;
        }

        &:nth-child(4) {
          background: url($static_path_modal + 'img/company-last-step-active.svg') center center no-repeat;
          margin-top: 3px;
          width: 230px;
          padding: 16px 10px 18px 54px;
        }
      }

      &.step-completed {
        &:nth-child(1) {
          background: url($static_path_modal + 'img/company-step-1-completed.svg') center center no-repeat;
          width: 250px;
        }

        &:nth-child(2) {
          background: url($static_path_modal + 'img/company-step-2.svg') center center no-repeat;
          width: 240px;
          padding: 19px 10px 18px 54px;
        }

        &:nth-child(3) {
          background: url($static_path_modal + 'img/company-step-2.svg') center center no-repeat;
          width: 240px;
          padding: 19px 10px 18px 54px;
        }
      }

      &:nth-child(2) {
        background: url($static_path_modal + 'img/company-step-2.svg') center center no-repeat;
        width: 240px;
        padding: 19px 10px 18px 54px;
      }

      &:nth-child(3) {
        background: url($static_path_modal + 'img/company-step-2.svg') center center no-repeat;
        width: 240px;
        padding: 19px 10px 18px 54px;
      }

      &:nth-child(4) {
        background: url($static_path_modal + 'img/company-last-step.svg') center center no-repeat;
        margin-top: 3px;
        width: 230px;
        padding: 16px 10px 18px 54px;

        .inside-step {
          .icons {
            width: 30px;
          }
        }
      }

      .inside-step {
        display: flex;
        align-items: center;

        .icons {
          margin-right: 16px;
          width: 32px;
          height: 32px;
          background-position: center center;
          background-size: 100%;
        }

        span {
          display: block;
          font-size: 16px;
          line-height: 24px;
          font-family: $font-semi;
          color: $gray_primary;
        }
      }
    }
  }
}