aside {
  position: fixed;
  top: 32px;
  z-index: 1;
  width: 216px;
  left: calc(50vw - ((1170px + 248px) / 2);
  display: flex;
  bottom: 32px;
  flex-direction: column;
  justify-content: space-between;

  .logo{
    margin-left: 22px;
  }

  .top-aside {
    .btn {
      padding: 8px 20px 6px 21px;
      margin-left: 2px;
    }
  }

  @media screen and (max-width: 1497px) and (min-width: 1280px) {
    left: 32px;
  }

  @media (max-width: 1279px){
    left: 32px;
    top: 32px;
    bottom: 32px;
    position: absolute;
  }
}

b.not-exist-name {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 130px;
  display: block;
}

.logged-in-block{
  display: flex;
  align-items: center;
  justify-content: space-between;
  .logged-in-info{
    display: flex;
    align-items: center;
    text-decoration: none;
    color: $dark_blue;
  }
  .logged-in-name{
    font-size: 14px;
    line-height: 20px;
  }
  .logged-in-img{
    width:40px;
    height: 40px;
    overflow: hidden;
    margin-right: 16px;
    img{
      display: block;
      border-radius: 50%;
      width:40px;
      height: 40px;
      object-fit: cover;
    }
  }
}

.nav{
  flex-direction: column;
  align-items: stretch;
  margin-bottom: 32px;

  .nav_item{
    width:100%;
    display: block;
    line-height: 24px;
    padding: 9px 10px 7px 60px;
    border-left: 2px solid transparent;
    position: relative;
    font-size:16px;
    border-radius: 2px;
    font-family: $font-semi;
    text-decoration: none;

    &.first-admin-link {
      margin-top: 32px;
    }

    .icons{
      position: absolute;
      top: calc(50% - 12px);
      left: 20px;
      color: #7F8FA4;
    }
    
    &:hover{
      text-decoration: none;
      color: #333C48;
      .home-outline{
        background-image: url($static_path + 'img/icons/home-filled.svg');
      }
      .notices-outline{
        background-image: url($static_path + 'img/icons/round-email-menu.svg');
      }
      .domains-outline{
        background-image: url($static_path + 'img/icons/domains-filled.svg');
      }
      .sender-icon{
        background-image: url($static_path + 'img/icons/senders-active.svg');
      }
      .users-outline{
        background-image: url($static_path + 'img/icons/users-filled.svg');
      }
      .orders-outline{
        background-image: url($static_path + 'img/icons/orders-filled.svg');
      }
      .billing-outline{
        background-image: url($static_path + 'img/icons/billing-filled.svg');
      }
      .public_off{
        background-image: url($static_path + 'img/icons/public_off_black_24dp_filled.svg');
      }
      .rule{
        background-image: url($static_path + 'img/icons/rule_black_24dp_filled.svg');
      }
    }

    &.admin-link {
      &.active {
        border-left-color: $orange;
      }
    }
    &.active{
      border-left-color: $green;
      color: #333C48;
      background: linear-gradient(to left, #F6F8F9 0%,rgba(#CCD2DB, .23) 100%) !important;
      .home-outline{
        background-image: url($static_path + 'img/icons/home-filled.svg');
      }
      .notices-outline{
        background-image: url($static_path + 'img/icons/round-email-menu.svg');
      }
      .users-outline{
        background-image: url($static_path + 'img/icons/users-filled.svg');
      }
      .domains-outline{
        background-image: url($static_path + 'img/icons/domains-filled.svg');
      }
      .orders-outline{
        background-image: url($static_path + 'img/icons/orders-filled.svg');
      }
      .billing-outline{
        background-image: url($static_path + 'img/icons/billing-filled.svg');
      }
      .public_off{
        background-image: url($static_path + 'img/icons/public_off_black_24dp_filled.svg');
      }
      .rule{
        background-image: url($static_path + 'img/icons/rule_black_24dp_filled.svg');
      }
    }
  }
}