.confirm-representative-page {
  .wrapper aside {
    display: none !important;
  }
}

.confirm-btn-group {
  flex-direction: row;
  display: flex;

  .group-item {
    width: 50%;

    &:first-child {
      padding-right: 10px;
    }

    &:last-child {
      padding-left: 10px;
    }
  }
}

html.register-page {
  body {
    background-color: $blue;
    width: 100%;
  }

  .logged-in-block,
  .top-aside,
  aside {
    display: none !important;
  }

  .login-page {
    padding-bottom: 20px;
    min-height: 100vh;
    display: block;
    height: auto;
  }
}

.login-page {
  input[readonly] {
    background-color: $gray_light_v2 !important;
    border: 1px solid $gray_light_v2 !important;
    color: $gray_secondary !important;
    font-family: $font-bold;
  }
}

.form-policy-block {
  padding: 20px 20px 0;
  text-align: center;
  font-family: $font;
  line-height: 26px;
  font-size: 18px;
  display: block;
  color: $white;
  width: 100%;
  color: $black;

  &.dark-text {
    color: $black;

    a {
      color: $black;
    }
  }

  a {
    text-decoration: underline;
    color: $white;

    &:hover {
      text-decoration: none;
    }
  }
}

.register-header {
  padding: 48px 138px 14px 138px;
  width: 100%;

  @media screen and (max-width: 1024px) {
    padding-left: 58px;
    padding-right: 58px;
  }

  @media screen and (max-width: 700px) {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
  }

  .logo {
    margin: 0;
  }
}

.login-title {
  font-family: $font-semi;
  margin-bottom: 56px;
  text-align: center;
  line-height: 48px;
  font-size: 40px;
  display: block;
  color: $white;
}

.form-footer-info {
  font-family: $font;
  text-align: center;
  padding-top: 42px;
  line-height: 22px;
  color: #333c48;
  font-size: 20px;
  display: block;

  &.small-text {
    line-height: 32px;
    font-size: 18px;
    display: block;
  }

  a {
    text-decoration: underline;
    font-family: $font-semi;
    color: $blue;

    &:hover {
      text-decoration: none;
    }
  }
}

.form-container {
  //padding: 0 20px;
}

.login-page{
  height:calc(100vh - 64px);
  min-height:480px;
  display: flex;
  align-items: center;
  justify-content:center;
  flex-direction: column;

  .login-block {
    width:480px;
    padding: 64px;
    background: $white;
    border-radius:6px;
    box-shadow: 0 2px 4px rgba(#333C48, .08);

    &.register-block {
      padding-bottom: 42px;
      padding-right: 80px;
      padding-left: 80px;
      padding-top: 42px;
      margin: 0 auto;
      width: 600px;

      @media screen and (max-width: 650px) {
        padding-left: 20px;
        padding-right: 20px;
        max-width: 100%;
        border-radius: 0;
        width: auto;
      }

      .form-title {
        font-family: $font-semi;
        margin-bottom: 45px;
        text-align: center;
        color: $dark_blue;
        line-height: 38px;
        font-size: 32px;
        display: block;
      }

      .form-group {
        margin-bottom: 29px;

        &.last-form-group {
          margin-bottom: 40px;
        }

        label {
          text-transform: capitalize;
          margin-bottom: 12px;
          font-size: 16px;
        }

        .form-control {
          &.form-control-lg {
            background-color: $gray_light_v2;
            border-color: $gray_light_v2;
            padding-bottom: 10px;
            padding-top: 12px;
            box-shadow: none;
            font-size: 18px;

            &:focus {
              border-color: $gray_primary;
              background-color: $white;
            }

            &.is-invalid {
              border-color: $red;
            }
          }
        }
      }
    }
  }

  .forgot-title{
    padding-top:48px;
    background: url('../img/icons/baseline-lock.svg') 50% 0 no-repeat;
    font-size:24px;
    font-family:$font;
    line-height: 29px;
    margin-bottom:24px;
    text-align: center;
    color: #333c48;

    &.reset-success {
      background: url('./../img/icons/check-circle.svg') 50% 0 no-repeat;
    }
  }

  p {
    color: #333c48;
    text-align: center;
    margin-bottom: 32px;
  }

  .invalid-login-block {
    text-align: left;
    background-color: $orange_lighen;
    width: 100%;
    font-size: 16px;
    line-height: 23px;
    font-family: $font;
    padding: 18px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 600px;
    width: 100%;
    margin: 0 auto 32px auto;

    @media screen and (max-width: 650px) {
      max-width: 100%;
      border-radius: 0;
    }

    a {
      color: $dark_blue;

      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }

    .icons {
      margin-right: 8px;
    }

    span {
      display: block;
      flex: 1;
    }
  }
}
