.existing-domain-warning {
  background-color: $gray_primary;
  border-radius: 3px;
  padding: 18px 24px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 54px;

  &.payment-form {
    position: relative;
    padding-left: 53px;
    flex-flow: wrap;

    .warning-info .icons {
      position: absolute;
      left: 15px;
    }
  }

  &.red-warning {
    margin-bottom: 16px;
    border: 1px solid $red;
    background-color: rgba(239, 72, 54, 0.2);

    .warning-info {
      span {
        color: $black;

        a {
          color: $black !important;
          line-height: 12px;
          display: inline-block;

          &:hover {
            border-color: $black;
          }
        }
      }
    }
  }

  .warning-info {
    display: flex;
    align-items: center;

    span {
      color: $white;
      display: block;

      a {
        color: $white !important;
        font-family: $font-semi;
        line-height: 12px;
        display: inline-block;

        &:hover {
          text-decoration: none;
          border-bottom: 1px solid $white;
        }
      }
    }

    .icons {
      margin-right: 10px;
    }
  }

  .close-existing-domain-warning {
    display: block;

    .icons {
      cursor: pointer;
       &.close-regular {
        &:hover {
          background-image: url($static_path + 'img/icons/close.svg')!important;
        }
      }
    }
  }
}