.react-modal {
  &.modal-add-notice {
    width: 734px;
    background-color: $white;
    border-radius: 6px;
    padding: 32px 31px;
    box-shadow: 0 2px 4px rgba($dark_blue, 0.08);
    position: relative;
  }
}

.modal-add-notice-body {
  .popup-title {
    margin: 0 0 29px 0;
    text-align: center;
  }

  .back-btn {
    position: absolute;
    top: 16px;
    left: 16px;
    cursor: pointer;
  }
}

.notice-container-items {
  .order-info-item {
    border-top: 1px solid #EFEFEF;
    padding-top: 17px;
    width: 100%;
  }

  .notes-list {
    padding-top: 19px; 
  }

  .files-added-block-list {
    margin-bottom: 0 !important;
  }
}
