.filter-input-block {
  padding-top: 25px;
  align-items: flex-end;
  display: none;

  &.active {
    display: flex;
  }

  &.three-form-group {
    padding-top: 0;
    .form-group {
      width: 576px;

      &:nth-child(2) {
        width: 270px;
      }

      &:nth-child(3) {
        width: 192px;
      }
    }
  }

  .form-group {
    width: 280px;
    margin-right: 30px;
    margin-bottom: 0;

    &.noticeform-column {
      width: 300px;
    }

    &.full-input {
      width: 100%;
      margin-right: 32px;
    }

    &:nth-child(3){
      width: 264px;
      margin-right: 34px;
    }

    &:last-child {
      width: 188px;
      margin-right: 0;
    }

    .input-field {
      position: relative;

      .form-control {
        padding: 4px 45px 2px 16px;
        height: 40px;
      }

      .react-select__single-value {
        // color: $gray_secondary;
        font-size: 16px;
        font-family: $font;
      }
    }

    .open-dropdown-menu {
      position: absolute;
      width: 40px;
      height: 100%;
      top: 0;
      right: 0;
      transform: translate3d(-50%, 0 0);
      background-color: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      outline: none;
      border: none;
      box-shadow: none;
      padding: 0;
    }
  }
}
