.order-info-container {
  .order-info-item {
    border-bottom: 1px solid $gray_light;
    align-items: flex-start;
    padding: 24px 0;
    display: flex;

    &.notice-item {
      border-bottom: none !important;
    }

    .order-title {
      line-height: 19px;

      @-moz-document url-prefix() {
        line-height: 22px
      }
    }

    &.checked-info {
      .info-container,
      .checked-header {
        opacity: 0.4;
      }

      .checked-header {
        &:not(.notification-status) {
          text-decoration: line-through;
        }
      }

      .normal-ul {
        .checkbox {
          &:after {
            content:'';
            width: 32px;
            height: 24px;
            background-color: $tr;
            position: absolute;
            top: 0;
            left: 0;
          }
        }
      }
    }

    &:last-child {
     border-bottom: 0;
    }

    .info {
      position: relative;
      &:nth-child(1) {
        width: 368px;
      }
      &:nth-child(2) {
        width: 400px;

        @media screen and (max-width: 1497px) {
          width: 36.1666%;
        }
      }
      &:nth-child(3) {
        width: 338px;

        @media screen and (max-width: 1497px) {
          width: 30.566%;
        }
      }

      &.checkbox-align-br {
        @-moz-document url-prefix() {
          display: flex;
          align-items: flex-start;
        }

        .notification-status {
          @-moz-document url-prefix() {
            padding: 4px 8px 4px;
          }
        }

        .checkbox {
          label {
             @-moz-document url-prefix() {
              line-height: 20px;
             }
          }
        }
      }
      .order-title {
        label {
          margin-bottom: 0;
        }
      }

      .order-small-title {
        margin-bottom: 8px;
      }

      .notification-status {
        position: relative;
        left: 0;
        margin-right: 16px;
        padding: 3px 8px 2px;
      }
    }

    .flex-1-container {
      width: 738px;

      @media screen and (max-width: 1497px) {
        width: 66.729%;
      }
    }

    p {
      margin-bottom: 0;
      color: $gray_primary;
    }
  }
}

.order-info-item {
  border-bottom: 1px solid $gray_light;
  align-items: flex-start;
  padding: 24px 0;
  display: flex;

  &.notice-item {
    border-bottom: none !important;
  }

  .order-title {
    line-height: 19px;

    @-moz-document url-prefix() {
      line-height: 22px
    }
  }

  &.checked-info {
    .info-container,
    .checked-header {
      opacity: 0.4;
    }

    .checked-header {
      &:not(.notification-status) {
        text-decoration: line-through;
      }
    }

    .normal-ul {
      .checkbox {
        &:after {
          content:'';
          width: 32px;
          height: 24px;
          background-color: $tr;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
  }

  &:last-child {
    border-bottom: 0;
  }

  .info {
    position: relative;
    &:nth-child(1) {
      width: 368px;
    }
    &:nth-child(2) {
      width: 400px;

      @media screen and (max-width: 1497px) {
        width: 36.1666%;
      }
    }
    &:nth-child(3) {
      width: 338px;

      @media screen and (max-width: 1497px) {
        width: 30.566%;
      }
    }

    &.checkbox-align-br {
      @-moz-document url-prefix() {
        display: flex;
        align-items: flex-start;
      }

      .notification-status {
        @-moz-document url-prefix() {
          padding: 4px 8px 4px;
        }
      }

      .checkbox {
        label {
          @-moz-document url-prefix() {
            line-height: 20px;
          }
        }
      }
    }
    .order-title {
      label {
        margin-bottom: 0;
      }
    }

    .order-small-title {
      margin-bottom: 8px;
    }

    .notification-status {
      position: relative;
      left: 0;
      margin-right: 16px;
      padding: 3px 8px 2px;
    }
  }

  .flex-1-container {
    width: 738px;

    @media screen and (max-width: 1497px) {
      width: 66.729%;
    }
  }

  p {
    margin-bottom: 0;
    color: $gray_primary;
  }
}

.custom-height-block {
  min-height: 40px;
  display: flex;
  align-items: center;
}

.removed-domains {
  padding: 9px 0 0 0;
  margin: 0;
  list-style-type: none;

  li {
    display: block;
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
