.react-modal {
  &.user-img-features-block {
    width: 322px;
    border-radius: 6px;
    background-color: $white;
    box-shadow: 0 2px 4px rgba(51,60,72, 0.08);
    padding: 32px;
    position: relative;
    margin-bottom: 50px;
  }
}

  .user-img-features-block-body {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    flex-direction: column;

    .close-user-img-features {
      width: 32px;
      height: 32px;
      display: block;
      position: absolute;
      top: 12px;
      right: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      img {
        display: block;
      }
    }

    .features-title {
      font-size: 24px;
      line-height: 29px;
      font-family: $font;
      color: $dark_blue;
      font-weight: 400;
      margin: 3px 0 29px 0;
      display: block;
    }

    .img-block {
      margin-bottom: 32px;
      .img {
        width: 160px;
        height: 160px;
        position: relative;

        .with-img {
          display: none;
        }

        &.user-with-img {
          &:hover {
            .hover-img {
              opacity: 1;
              visibility: visible;
            }
          }
          .without-img {
            display: none;
          }

          .with-img {
            display: block;
            width: 160px;
            height: 160px;
            border-radius: 50%;
          }

          .upload-img {
            display: none;
          }
        }

        .upload-img {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 49%;
          left: 50%;
          transform: translate3d(-50%, -50%, 0);
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;

          img {
            display: block;
            margin-bottom: 18px;
          }

          label {
            cursor: pointer;
            font-size: 16px;
            line-height: 10px;
            text-decoration: none;
            color: $blue;
            margin: 0;
          }

          input {
            position: absolute;
            left: 0;
            right: 0;
            height: 100%;
            width: 100%;
            opacity: 0;
            cursor: pointer;
          }
        }
      }
    }

    .img-features-btn-block {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .btn {
        &:first-child {
          padding: 8px 29px 8px 31px;
        }
        &:last-child {
          padding: 8px 47px 8px 46px;
        }
      }
    }

    .hover-img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($dark_blue, 0.88);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      opacity: 0;
      visibility: hidden;
      transition: visibility 0.5s ease-in-out, opacity 0.5s ease-in-out;

      .change-img-block {
        margin-bottom: 16px;
        padding-top: 9px;
        input {
          display: none;
        }

        label {
          font-size: 16px;
          line-height: 16px;
          font-family: $font-semi;
          color: $blue;
          display: block;
          margin: 0;
          cursor: pointer;
        }
      }

      .delete-avatar {
        font-size: 16px;
        line-height: 16px;
        font-family: $font-semi;
        color: $red;
        display: block;
        margin: 0;
        cursor: pointer;
      }
    }
  }
