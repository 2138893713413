* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-family: $font;
}

html,
body {
	min-height: 100%;
}

body {
	font-family: $font;
	font-size: 16px;
	line-height: 24px;
	background: #F6F8F9 !important;
	color: #333C48;
	padding: 32px;
	height: auto;

	&.ReactModal__Body--open {
		overflow: hidden;
	}
}

b, strong {
	font-family: $font-semi;
	font-weight: normal;
}

p {
	font-size: 16px;
	line-height: 24px;
	color: $dark_blue;
	margin-bottom: 24px;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
	font-family: $font-semi;
	margin-bottom: 27px;
}

a {
	color: $gray_primary;
	&:hover {
		color: $dark_blue;
		text-decoration: underline;
	}
}


