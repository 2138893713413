.last-page__btn{
  font-weight: bold;
  text-decoration: underline;
}

.pagination-container {
  position: relative;
}

.pagination-block {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);

  .break-me {
    width: 32px;
    text-align: center;
    margin-left: -8px;
  }

  .pagination-item {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }

    &.active {
      a {
        background-color: $dark_blue;
        color: $white;
        border-color: transparent;

        &:hover {
          color: $white;
        }
      }
    }

    button, a {
      @include size(32px,32px);
      outline: none;
      display: block;
      border: 1px solid $gray_secondary;
      border-radius: 3px;
      font-family: $font-semi;
      font-size: $h6-font-size;
      color: $gray_primary;
      cursor: pointer;
      text-align: center;
      text-decoration: none;
      padding: 4px;
      box-shadow: 0 1px 1px rgba(51,60,70,0.10);
      background-color: transparent;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;

      &:hover{
        color: rgba($dark_blue, .8);
        border-color: rgba($gray_primary, .8);
        box-shadow: 0 2px 4px 0 rgba($dark_blue, .1);
      }

      &.prev {
        background-image: url($static_path + 'img/icons/left.svg');
        background-position: center center;
      }

      &.next {
        background-image: url($static_path + 'img/icons/right.svg');
        background-position: center center;
      }
    }
  }
}
