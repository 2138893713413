.btn {
  line-height: 16px;
  padding: 8px 16px 6px 16px;
  border: none;
  display: inline-block;
  font-size: 14px;
  border-radius: 3px;
  font-weight: normal;
  font-family: $font-semi;
  text-decoration: none;
  opacity: 1;
  outline: none;

  &.btn-small-loading {
    min-width: 150px;

    &.loading {
      &:after {
        margin-top: -8px;
        height: 16px;
        right: 8px;
        width: 16px;
      }
    }

    &.animate-loading {
      transition: padding ease 300ms;

      &.loading {
        padding-right: 32px;
      }
    }
  }
  &.btn-xs-loading {
    &.loading {
      &:after {
        margin-top: -8px;
        height: 16px;
        right: 8px;
        width: 16px;
      }
    }

    &.animate-loading {
      transition: padding ease 300ms;

      &.loading {
        padding-right: 32px;
      }
    }
  }
  &.capitalize {
    text-transform: capitalize !important;
  }

  &.no-button-actions {
    cursor: default !important;
  }

  &-synced {
    color: #7F8FA4;
    background: #fff;
    border: 1px solid #CCD2DB;
    padding: 8px 24px 8px;
    border-radius: 3px;
    margin-right: 12px;
    font-size: 14px;
    line-height: 18px;
    &__icon{
      transition: .3s;
      background-repeat: no-repeat;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='16' fill='%2300bb28' viewBox='0 0 24 24'%3E%3Cpath d='M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.25 17.292l-4.5-4.364 1.857-1.858 2.643 2.506 5.643-5.784 1.857 1.857-7.5 7.643z'/%3E%3C/svg%3E");
      background-position: 50px 0;
      padding-right: 22px;
      width: 5px;
      height: 5px;
    }
  }

  &.plus-icon {
    position: relative;
    padding-left: 42px;

    &:before {
      justify-content: center;
      align-items: center;
      position: absolute;
      margin-top: -4px;
      font-size: 25px;
      display: flex;
      height: 10px;
      content: '+';
      width: 10px;
      left: 20px;
      top: 50%;
    }
  }

  &.plus-icon-small {
    position: relative;
    padding: 8px 16px 6px 32px;
    min-width: 110px;

    &:before {
      justify-content: center;
      align-items: center;
      position: absolute;
      margin-top: -4px;
      font-size: 25px;
      display: flex;
      height: 10px;
      content: '+';
      width: 10px;
      left: 11px;
      top: 50%;
    }
  }

  &.loading {
    position: relative;

    &:after {
      border-color: #fff #fff #fff transparent;
      -webkit-animation:ld-spin 1s infinite;
      animation:ld-spin 1s infinite;
      border-radius: 50%;
      border-style: solid;
      border-width: 3px;
      display: block;
      position: absolute;
      margin-top: -10px;
      height: 20px;
      content: '';
      width: 20px;
      right: 27px;
      top: 50%;
    }

    .icons {
      opacity: 0;
    }

    &.btn-red {
      &:after {
        border-color: $red $red $red transparent;
      }
    }
  }

  &.btn-blue-default {
    height: 40px;
  }

  &.no-shadow {
    box-shadow: none !important;
  }

  &.transparent {
    background-color: transparent;
  }

  &.link-btn {
    background-color: transparent !important;
    border-width: 0 !important;
    text-decoration: none;
  }

  &.link-btn.btn-sm {
    padding-right: 15px;
    padding-left: 15px;
  }

  &.btn-sm-transparent-icon {
    padding: 0;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
  }

  &.btn-md {
    font-size: 16px;
    padding: 8px 32px 6px 32px;
  }

  &.btn-md-icon-right {
    font-size: 16px;
    padding: 8px 16px 6px 32px;

    .icons {
      margin-left: 17px;
      margin-right: 0;

      &:first-child {
        margin-right: 0;
      }
    }
  }

  &.btn-sm-icon-right {
    font-size: 14px;
    padding: 4px 15px 2px 15px;

    .icons {
      margin-left: 18px;
      margin-right: 0;

      &.company-open {
        margin-left: 8px;
      }

      &:first-child {
        margin-right: 0;
      }
    }

    &.only-arrow {
      padding: 4px 4px 2px 15px;
      display: flex;
      align-items: center;

      .icons {
        margin-left: 9px;
      }
    }
  }

  &.btn-md-icon-left {
    line-height: 24px;
    font-size: 16px;
    padding: 8px 32px 6px 16px;
  }
  &.btn-db {
    display: block;
    width: 100%;
  }

  &.btn-w100 {
    width: 100% !important;
  }
  .icons:first-child {
    margin-right: 8px;
    &.left {
      top: -1px;
      position: relative;
    }

    &.filter {
      margin-right: 0;
      margin-left: 16px;
    }
  }

  &.btn-with-icon {
    .icons {
      background-position-y: 50%;
      transition: right .2s;
      position: relative;
      top: auto;
      right: 0;
    }

    &:hover {
      .icons {
        right: 15px;
      }
    }

    .left {
      margin-left: -12px;
      margin-top: -5px;
      margin-right: 7px;
    }

    &.left {
      position: relative;
      padding-left: 60px;

      &:hover {
        .icons {
          left: 18px;
        }
      }

      .icons {
        transform: rotate(-180deg) translate(0, 50%);
        transition: left .2s;
        position: absolute;
        margin-top: -1px;
        left: 29px;
        top: 50%;
      }
    }
  }

  &.btn-white {
    color: $gray_primary;
    background: $white;
    border: 1px solid $gray_secondary;
    box-shadow: 0 1px 1px rgba($dark_blue, .1);

    &:hover {
      color: rgba($dark_blue, .8);
      border-color: rgba($gray_primary, .8);
      // box-shadow: 0 2px 4px 0 rgba($dark_blue, .1);
      .icons{
        transform: translateX(5px);
      }
    }
    &.btn-lg {
      box-shadow: 0 1px 1px rgba($dark_blue, .10);
      // &:hover {
      //   box-shadow: 0 2px 4px 0 rgba($dark_blue, .10);
      // }
    }

    &.loading:after {
      border-color: $gray_primary $gray_primary $gray_primary transparent;
    }
  }

  &.btn-green {
    color: $white !important;
    background: $green;
    border: 1px solid $green;
    box-shadow: 0 1px 1px rgba($green, .1);
    &:not(.no-button-actions):hover {
      color: $white;
      background-color: $green_hover;
      border-color: $green_hover;
      // box-shadow: 0 2px 4px rgba($green_hover, .4);
    }

    &.btn-bordered {
      background: $white;
      color: $green;
      border-color: $green;
      &:hover {
        background-color: $white;
        color: $green_hover;
        // box-shadow: 0 2px 4px rgba($green_hover, .4);
      }
    }
    &.btn-lg {
      box-shadow: 0 1px 1px rgba($dark_blue, .10);
      &:hover {
        // box-shadow: 0 2px 4px rgba($green_hover, .4);
      }
    }
  }
  &.btn-orange {
    color: $white;
    background: $orange;
    border: 1px solid $orange;
    box-shadow: 0 1px 1px rgba($orange, .1);

    &.xero-reconnect {
      width: 50%;
      display: block;
      margin: 0 auto;
      margin-bottom: 20px;
      margin-top: 10px;
    }

    &:hover {
      color: $white;
      background-color: $orange_hover;
      border-color: $orange_hover;
      // box-shadow: 0 2px 4px rgba($orange_hover, .4);
    }
    &.btn-bordered {
      background: $white;
      color: $orange;
      border-color: $orange;
      &:hover {
        background-color: $white;
        color: $orange_hover;
        // box-shadow: 0 2px 4px rgba($orange_hover, .4);
      }
    }
    &.btn-lg {
      box-shadow: 0 1px 1px rgba($dark_blue, .10);
      &:hover {
        // box-shadow: 0 2px 4px rgba($orange_hover, .24);
      }
    }

    &:disabled {
      opacity: 0.7;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &.btn-light-orange {
    color: $white;
    background: $orange_fb;

    &:hover {
      background: $orange_hover;
    }
  }

  &.btn-light-blue {
    color: $white;
    background: $blue_0c;

    &:hover {
      background: $blue_hover;
    }
  }

  &.arrow-btn {
    .icons:last-child {
      position: relative;
      transition: left .2s;
      top: -3px;
      left: 25px;
      margin-left: 4px;
    }
    &:hover {
      .icons:last-child {
        left: 41px;
      }

      //@media screen and (max-width: 1497px) {
      //  .icons:last-child {
      //    left: 7px;
      //  }
      //}
    }

    &.custom-arrow {
      padding-left: 43px !important;
      position: relative;
      min-width: 234px;
      text-align: left;

      @media screen and (max-width: 1497px) {
        padding-left: 31px !important;
        min-width: 228px;
      }

      .icons:last-child {
        position: absolute;
        transition: right .2s;
        margin-right: 0;
        right: 25px;
        top: 7px;
        left: auto;
      }

      &:hover {
        .icons:last-child {
          right: 10px;
        }

        @media screen and (max-width: 1497px) {
          .icons:last-child {
            right: 10px;
          }
        }
      }
    }
    &.btn-db {
      position: relative;
      .icons {
        position: absolute;
        right: 32px;
        top: calc(50% - 12px);
        left: auto;
        transition: right .2s;
      }
      &:hover {
        .icons:last-child {
          left: auto;
          right: 16px;
        }
      }
    }
  }

  &.arrow-btn-right {
    position: relative;
    .icons {
      position: absolute;
      top: calc(50% - 12px);
      transition: right .2s;
      right: 0;
      //
      //&:last-child {
      //  left: auto;
      //  right: 10px;
      //}
    }

    &:hover {
      .icons {
        left: auto;
        right: -5px;
      }
    }
  }

  &.btn-blue {
    color: $white !important;
    background: $blue;
    border: 1px solid $blue;
    box-shadow: 0 2px 4px 0 rgba(59, 161, 237, 0.24);
    &:hover {
      color: $white;
      background-color: $blue_hover;
      border-color: $blue_hover;
      // box-shadow: 0 4px 6px 0 rgba(15, 125, 209, 0.4);
    }
    &.btn-bordered {
      background: $white;
      color: $blue;
      border-color: $blue;
      &:hover {
        background-color: $white;
        color: $blue_hover;
        // box-shadow: 0 4px 6px 0 rgba(15, 125, 209, 0.4);
      }
    }
    &.btn-lg {
      box-shadow: 0 1px 1px rgba($dark_blue, .10);
      &:hover {
        // box-shadow: 0 4px 6px 0 rgba(15, 125, 209, 0.4);
      }
    }
  }
  &.btn-red {
    color: $white;
    background: $red;
    border: 1px solid $red;
    box-shadow: 0 1px 1px rgba($red, .1);
    &:hover {
      color: $white;
      background-color: darken($red, 10%);
      border-color: darken($red, 10%);
      // box-shadow: 0 2px 4px rgba($red, .1);
    }
    &.btn-bordered {
      background: $white;
      color: $red;
      border-color: rgba($red, 0.6);
      &:hover {
        background-color: $white;
        border-color: $red;
        color: $red;
        // box-shadow: 0 2px 4px rgba($red, .1);
      }
    }
  }

  &.btn-lg {
    line-height: 24px;
    font-size: 20px;
    padding: 17px 32px;
  }
  &.btn-medium {
    line-height: 16px;
    font-size: 16px;
    padding: 8px 24px 8px;
    height: 40px;
  }

  &.btn-medium-icon-right {
    padding: 8px 15px 8px 24px;
    height: 40px;
    line-height: 16px;
    font-size: 16px;
    .icons {
      margin-right: 0;
      margin-left: 16px;

      &.download-hover {
        margin-right: 0;
      }
    }
  }
  &.btn-sm {
    line-height: 24px;
    font-size: 16px;
    padding: 8px 32px 8px 31px;
    height: 40px;

    &.plus-icon {
      padding-left: 42px;
    }

    &.loading {
      &:after {
        margin-top: -7px;
        height: 15px;
        content: '';
        width: 15px;
        right: 9px;
      }
    }
  }

  &.btn-small {
    line-height: 16px;
    font-size: 16px;
    padding: 4px 8px 2px;
    text-transform: uppercase;
    margin-bottom: 13px;
  }

  &.btn-white-small {
    padding: 8px 16px 6px 17px;
  }

  &.btn-icon-w-188 {
     padding: 8px 24px 6px 16px;
     min-width: 188px;
     display: flex;
     align-items: center;

    &-pr-16 {
      padding-right: 16px;
    }

    .icons {
      margin-right: 15px;
    }
  }

  &.btn-icon-w-100 {
    padding: 8px 10px 6px 10px;
    width: 100%;
    display: flex;
    align-items: center;

    .icons {
      margin-right: 10px;
    }
  }
}

.btn-icon {
  color: $gray_secondary;
  text-decoration: none;
  position: relative;
  font-family: $font-semi;
  height: 24px;
  line-height: 24px;
  vertical-align: middle;
  display: inline-block;
  .btn-icon-text {
    position: absolute;
    right: calc(100% + 8px);
    top: 50%;
    transform: translateY(-50%);
    font-size: 14px;
    font-family: $font_semi;
    color: $gray_secondary;
    display: none;
  }
  .icons {
    display: block;
  }
  &:hover {
    color: $dark_blue;
    text-decoration: none;
    .btn-icon-text {
      display: block;
      color: $dark_blue;
    }
    .tooltip-regular {
      background-image: url('../img/icons/tooltip-hover.svg');
    }
    .close-regular {
      background-image: url('../img/icons/close-hover.svg');
    }
    .download {
      background-image: url('../img/icons/download-hover.svg');
    }
  }
}

.btns-block{
  display: flex;
  align-items: center;
  .btn{
    margin-bottom: 0;
    margin-right:16px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.remove-file {
  border: none;
  background-color: transparent;
  outline: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.trash-button {
  &.loading {
    .icons {
      opacity: 0;
    }

    position: relative;

    &:after {
      border-color: $gray_primary $gray_primary $gray_primary transparent;
      -webkit-animation:ld-spin 1s infinite;
      animation:ld-spin 1s infinite;
      border-style: solid;
      border-radius: 50%;
      position: absolute;
      border-width: 3px;
      cursor: progress;
      display: block;
      height: 17px;
      content: '';
      width: 17px;
      right: 3px;
      top: 3px;
    }
  }
}

.btn-border-red {
  border-color: $red!important;
}

.btn-control {
  position: relative;
  min-height: 48px;
  padding-top: 16px;
  padding-left: 16px;
  .btn {
    margin-bottom: 0;
  }
}
