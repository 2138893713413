.hide {
  display: none !important;
}

.mt-min-32 {
  margin-top: -32px !important;
}

.relative {
  position: relative !important;
}

.absolute {
  position: absolute !important;
}

.absolute-list-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  right: 0;
  background: #fff;
  padding: 10px;
  border-radius: 8px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  top: 40px;
  z-index: 100;

}

.absolute-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0;
  font-size: 14px;
  border-bottom: 1px solid $gray_light;
  width: 100%;

  .title {
    margin-right: 10px;
  }

  .counter {
    padding: 4px 4px 2px 4px;
    border-radius: 3px;
    background: $gray_secondary;
    color: #fff;
    white-space: nowrap;
    line-height: 14px;
  }

  &:last-child {
    border-bottom: 0px;
  }
}

.loader-wrapper {
  position: absolute !important;
  right: 10px;
  top: -10px;
  bottom: -10px;
  left: 10px;
  background-color: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(10px);
  color: $gray_78;
}

.loaderSpinner {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite
}
.loaderSpinner::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 3px solid #F7981C;
  animation: prixClipFix 2s linear infinite ;
}

.buttonLoader {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite
}
.buttonLoader::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 2px solid $gray_primary;
  animation: prixClipFix 2s linear infinite ;
}

@keyframes rotate {
  100%   {transform: rotate(360deg)}
}

@keyframes prixClipFix {
  0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
  25%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
  50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
  75%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}
  100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}
}

.break-all {
  word-break: break-all;
}

.disabled {
  opacity: 0.3;
}

.d-ib {
  display: inline-block !important;
}
//
//.d-t {
//  display: table !important;
//}

.header-col {
  display: block;
  margin-right: -15px;
  margin-left: -15px;
}

.one-line-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 95%;
  display: block;

  span {
    display: block;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.no-horizontal-padding {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.w-30 {
  width: 30%;
}

.w-30-important {
  width: 30% !important;
}

.min-w-0 {
  min-width: 0 !important;
}

.bottom-loading {
  background-color: rgba($gray_light_v2, 0.6);
  max-width: 1170px;
  position: fixed;
  height: 70px;
  z-index: 100;
  right: auto;
  width: 100%;
  bottom: 0;
  left: auto;

  &:before {
    border-color: $green $green $green transparent;
    -webkit-animation: ld-spin 1s infinite;
    animation: ld-spin 1s infinite;
    border-radius: 50%;
    border-style: solid;
    border-width: 3px;
    display: block;
    position: absolute;
    margin-left: -20px;
    margin-top: -20px;
    height: 40px;
    content: '';
    width: 40px;
    left: 50%;
    top: 50%;
  }
}

.loading-container {
  position: relative;

  &:before {
    border-color: $green $green $green transparent;
    -webkit-animation: ld-spin 1s infinite;
    animation: ld-spin 1s infinite;
    border-radius: 50%;
    border-style: solid;
    border-width: 3px;
    display: block;
    position: absolute;
    margin-left: -30px;
    margin-top: -30px;
    height: 60px;
    content: '';
    width: 60px;
    left: 50%;
    top: 50%;
  }

  &:after {
    content: 'Loading...';
    position: absolute;
    margin-left: -70px;
    text-align: center;
    display: block;
    color: $green;
    width: 140px;
    left: 50%;
    top: 57%;
  }

  .no-data-container {
    opacity: 0;
  }
}

.noMinWidth {
  min-width: 0 !important;
}

.max-w160 {
  max-width: 160px;
}

.max-w150 {
  max-width: 150px;
}

.payment-image {
  margin-right: 10px;
  max-width: 60px;

  &.bigger-icon {
    max-width: 77px;
  }
}

.black {
  color: $black;
}

.no-wrapp {
  white-space: nowrap;
}

.is-link {
  cursor: pointer;
}

.dark_blue {
  color: $dark_blue;
}

.gray_primary {
  color: $gray_primary;
}

.gray_secondary {
  color: $gray_secondary;
}

.pink {
  color: $pink;
}

.gray_light {
  color: $gray_light;
}

.gray_light_v2 {
  color: $gray_light_v2;
}

.white {
  color: $white;
}

.link-block {
  display: block;
}

.lowercase {
  text-transform: lowercase;
}

.capitalize {
  text-transform: capitalize !important;
}

.no-transform {
  text-transform: none !important;
}

.orange {
  color: $orange;
}

.orange_hover {
  color: $orange_hover;
}

.orange_lighen {
  color: $orange_lighen;
}

.blue {
  color: $blue;
}

.blue_hover {
  color: $blue_hover;
}

.blue_lighen {
  color: $blue_lighen;
}

.green {
  color: $green;
}

.green_hover {
  color: $green_hover;
}

.green_lighen {
  color: $green_lighen;
}

.red {
  color: $red;
}

.normal-ul {
  margin: 0;
  padding: 0;
  list-style-type: none;

  &.info-container {
    li {
      display: flex;
      align-items: center;
    }
  }
}

.email-error {
  width: 24px;
  height: 24px;
  display: inline-block;
  background-image: url('../img/icons/email-error.svg');
  background-position: 0 -2px;
  vertical-align: middle;
  margin-left: 14px;
}

.toggle_block_btn {
  cursor: pointer;
}

.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.flex-content-between {
  justify-content: space-between !important;
}

.flex-content-end {
  justify-content: flex-end;
}

.flex-content-center {
  justify-content: center;
}

.flex-d-c {
  flex-direction: column;
}

.flex-1 {
  flex: 1;
}

.cursor {
  cursor: pointer;
}

.full-width {
  width: 100%!important;
}

.w-161 {
  min-width: 161px !important;
}

.min-height-24 {
  min-height: 24px;
}

.min-width-123 {
  min-width: 123px;
}

.w-167 {
  width: 167px;
}

.w-153 {
  width: 153px;
}

.w-149 {
  width: 149px;
}

.w-72 {
  width: 72px;
}

.w-319 {
  width: 319px;
}

.w-326 {
  width: 326px;
}

.w-399 {
  width: 399px;
}

.w-468 {
  width: 468px;
}

.w-120 {
  width: 120px;
}

.w-185 {
  width: 185px;
}

.w-188 {
  width: 188px;
}

.w-178 {
  width: 178px;
}

.w-320 {
  width: 320px;
}

.w-230 {
  width: 230px;
}

.w-195 {
  width: 195px;
}

.max-32-height {
  .btn {
    max-height: 32px;
  }
}

.w-353 {
  width: 353px;
}
.w-553 {
  width: 553px;
}

.min-w-131 {
  min-width: 131px;
}

.min-w-105 {
  min-width: 105px;
}

.min-w-110 {
  min-width: 110px;
}

.w-537 {
  width: 537px;
}
.full-width-dmca{
  @media screen and (max-width: 1497px) {
    width: 100%;
  }
}
.half-width-dmca{
  @media screen and (max-width: 1497px) {
    width: 50%;
  }
}

.half-width-padding {
  @media screen and (max-width: 1497px) {
    padding-right: 17px;
  }
}

.w-48-percentage {
  @media screen and (max-width: 1497px) {
    width: 48.5%;
  }
}

.w-368 {
  width: 368px;
}
.w-300 {
  width: 300px;
}

.w-191 {
  width: 191px!important;
}

.w-272 {
  width: 272px;
}
.paragraph-with-right-icon {
  .icons {
    margin-right: 8px;
  }
}
.dib {
  display: inline-block;
}

.db {
  display: block !important;
}

.ml-auto {
  margin-left: auto;
}

.mr-6 {
  margin-right: 6px;
}

.ml-4 {
  margin-left: 4px!important;
}

.ml-7 {
  margin-left: 7px!important;
}

.ml-10 {
  margin-left: 10px!important;
}

.ml-28 {
  margin-left: 28px!important;
}

.mr-32 {
  margin-right: 32px;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-29 {
  margin-bottom: 29px;
}

.mb-24 {
  margin-bottom: 24px;
}

.mb-17 {
  margin-bottom: 17px!important;
}

.mb-15 {
  margin-bottom: 15px!important;
}

.mb-19 {
  margin-bottom: 19px!important;
}

.mb-38 {
  margin-bottom: 38px!important;
}

.mb-32 {
  margin-bottom: 32px!important;
}

.mb-46 {
  margin-bottom: 46px!important;
}

.mb-40 {
  margin-bottom: 40px!important;
}

.mb-6 {
  margin-bottom: 6px!important;
}

.mb-24 {
  margin-bottom: 24px!important;
}

.mb-25 {
  margin-bottom: 25px!important;
}

.mb-16 {
  margin-bottom: 16px!important;
}

.mb-8 {
  margin-bottom: 8px;
}

.font-size-14 {
  font-size: 14px;
}

.ml-auto {
  margin-left: auto;
}

.pr-10 {
  padding-right: 10px;
}

.mt-7 {
  margin-top: 7px;
}

.mt-19 {
  margin-top: 19px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-12 {
  margin-right: 12px!important;
}

.mr-16 {
  margin-right: 16px!important;
}

.mr-24 {
  margin-right: 24px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-40 {
  margin-left: 40px;
}

.ml-16 {
  margin-left: 16px;
}

.mt-21 {
  margin-top: 21px;
}

.mt-32 {
  margin-top: 32px;
}

.pt-9-pc {
  padding-top: 9%;
}

.pt-0 {
  padding-top: 0;
}

.pt-24 {
  padding-top: 24px;
}

.pt-16 {
  padding-top: 16px;
}

.pt-10 {
  padding-top: 10px;
}

.pt-2 {
  padding-top: 2px!important;
}

.pt-4 {
  padding-top: 4px!important;
}

.pl-10 {
  padding-left: 10px !important;
}

.pr-10 {
  padding-right: 10px !important;
}

.pr-22 {
  padding-right: 22px;
}

.pl-30 {
  padding-left: 30px;
}

.pl-35 {
  padding-left: 35px;
}

.pl-0 {
  padding-top: 0;
}

.lh-32 {
  line-height: 32px;
  min-height: 32px;
}

.lh-40 {
  line-height: 40px!important;
}

.w-255 {
  width: 255px !important;
}

.order-details-min-col-width {
  min-width: 230px !important;
  padding-right: 30px;
}

.w-181 {
  width: 181px !important;
}

.focus-input {
  .calendar-svg {
    svg {
      path {
        fill: rgba(51, 60, 72, .8);
      }
    }
  }
}

.icons {
  display: inline-block;
  vertical-align: middle;
  line-height: inherit;
  width: 24px;
  height: 24px;
  vertical-align: middle;
  background-position: 0 0;
  background-repeat: no-repeat;
  background-color: transparent;

  &:hover {
    &.delete-regular {
       background-image: url('../img/icons/delete-hover.svg');
    }

    &.close-regular {
       background-image: url('../img/icons/close-hover.svg');
    }

    &.back-regular {
      background-image: url('../img/icons/back-hover.svg');
    }
  }

  &.small-size {
    width: 14px;
    height: 14px;
    background-size: cover;
  }

  &.filter {
    background-image: url('../img/icons/filter-icon.svg');
  }
  &.add-black {
    background-image: url('../img/icons/add-black.svg');
  }
  &.add {
    background-image: url('../img/icons/add.svg');
  }
  &.add-hover {
    background-image: url('../img/icons/add-hover.svg');
  }
  &.add-domain {
    background-image: url('../img/icons/add-domain.svg');
  }
  &.back-hover {
    background-image: url('../img/icons/back-hover.svg');
  }
  &.back-regular {
    background-image: url('../img/icons/back-regular.svg');
  }

  &.notes-icon {
    background-image: url('../img/icons/notes-icon.svg');
  }

  &.baseline-lock {
    background-image: url('../img/icons/baseline-lock.svg');
  }

  &.users-not-found-icon {
    background-image: url('../img/icons/users-not-found-icon.svg');
  }

  &.notice-not-found-icon {
    background-image: url('../img/icons/notice-not-found-icon.svg');
  }

  &.sign-in-history {
    background-image: url('../img/icons/sign-in-history-icon.svg');
  }

  &.domain-not-found-icon {
    background-image: url('../img/icons/domain-not-found-icon.svg');
  }

  &.order-not-found-icon {
    background-image: url('../img/icons/order-not-found-icon.svg');
  }

  &.billing-filled {
    background-image: url('../img/icons/billing-filled.svg');
  }

  &.calendar {
    background-image: url('../img/icons/calendar.svg');

    &.calendar-svg {
      background-image: none;

      svg {
        margin-left: 8px;
        margin-top: 8px;

        path {
          transition: fill ease 0.15s;
        }
      }
    }
  }

  &.billing-outline {
    background-image: url('../img/icons/billing-outline.svg');
  }
  &.upload-img-logo-white {
    background-image: url('../img/icons/upload-img-logo-white.svg');
  }
  &.billing {
    background-image: url('../img/icons/billing.svg');
  }
  &.description {
    background-image: url('../img/icons/description.svg');
  }
  &.email-warning {
    background-image: url('../img/icons/email-warning.svg');
  }
  &.source-icon {
    background-image: url('../img/icons/source-icon.svg');
  }
  &.bounding-boxes {
    background-image: url('../img/icons/bounding-boxes.svg');
  }
  &.check-circle {
    background-image: url('../img/icons/check-circle.svg');
  }
  &.check-circle-black {
    background-image: url('../img/icons/check-circle-black.svg');
  }
  &.check-circle-gray {
    background-image: url('../img/icons/check-circle-gray.svg');
  }
  &.payment-circle {
    background-image: url('../img/icons/payment-circle.svg');
  }
  &.payment-circle-black {
    background-image: url('../img/icons/payment-circle-black.svg');
  }
  &.checkmark-regular {
    background-image: url('../img/icons/checkmark-regular.svg');
    background-position: 0 0px;
    width: 18px;
    height: 19px;

    // &.orange {
    //   background-position: 0 -1px;
    // }

    // &.center-position {
    //    background-position: 0 -1px;
    // }
  }
  &.checkmark-filled {
    background-image: url('../img/icons/checkmark-filled.svg');
  }
  &.checkmark-hover {
    background-image: url('../img/icons/checkmark-hover.svg');
  }
  &.close-hover {
    background-image: url('../img/icons/close-hover.svg');
  }
  &.close-black {
    background-image: url('../img/icons/close-black.svg');
  }
  &.close-regular {
    background-image: url('../img/icons/close.svg');
    cursor: pointer;
  }
  &.company-details {
    background-image: url('../img/icons/company-details.svg');
  }
  &.company-details-hover {
    background-image: url('../img/icons/company-details-hover.svg');
  }
  &.delete-hover {
    background-image: url('../img/icons/delete-hover.svg');
  }
  &.owner-icon {
    background-image: url('../img/icons/owner-icon.svg');
  }
  &.warning {
    background-image: url('../img/icons/warning.svg');
  }
  &.warning-white {
    background-image: url('../img/icons/warning-white.svg');
  }
  &.warning-black {
    background-image: url('../img/icons/warning-black.svg');
  }
  &.warning-red {
    background-image: url('../img/icons/warning-red.svg');
  }
  &.delete-hover-red {
    background-image: url('../img/icons/delete-hover-red.svg');
  }
  &.delete-regular {
    background-image: url('../img/icons/delete-regular.svg');
  }
  &.domains-filled {
    background-image: url('../img/icons/domains-filled.svg');
  }
  &.domains-light-gray {
    background-image: url('../img/icons/domains-light-gray.svg');
  }
  &.domains-outline {
    background-image: url('../img/icons/domains-outline.svg');
  }
  &.last_update {
    background-image: url('../img/icons/update.svg');
    padding-bottom: 30px;
    margin-left: 10px;
    margin-left: 4px;
  }
  &.domains-outline-gray {
    background-image: url('../img/icons/domains-outline-gray.svg');
  }
  &.download {
    background-image: url('../img/icons/download.svg');
    background-position: 0 -2px;
  }
  &.download-hover {
    background-image: url('../img/icons/download-hover.svg');
    background-position: 0 -2px;
  }
  &.dropdown-down {
    background-image: url('../img/icons/dropdown-down.svg');
  }
  &.dropdown-up {
    background-image: url('../img/icons/dropdown-up.svg');
  }
  &.event-feed {
    background-image: url('../img/icons/event-feed.svg');
  }
  &.history-feed {
    background-image: url('../img/icons/history-feed.svg');
  }
  &.time-icon {
    background-image: url('../img/icons/time-icon.svg');
  }
  &.home-outline {
    background-image: url('../img/icons/home-outline.svg');
  }
  &.home-filled {
    background-image: url('../img/icons/home-filled.svg');
  }
  &.left {
    background-image: url('../img/icons/left.svg');
  }
  &.logout {
    background-image: url('../img/icons/logout.svg');
  }
  &.notices-outline {
    background-image: url('../img/icons/notices-outline.svg');
  }
  &.open-in-new-hover {
    background-image: url('../img/icons/open-in-new-hover.svg');
  }
  &.open-in-new {
    background-image: url('../img/icons/open-in-new.svg');
    background-position: 0 -2px;
  }
  &.open-in-new-white {
    background-image: url('../img/icons/open-in-new-white.svg');
    background-position: 0 -1px;
  }
  &.order-details {
    background-image: url('../img/icons/order-details.svg');
  }
  &.documents-icon {
    background-image: url('../img/icons/documents-icon.svg');
  }
  &.orders-outline {
    background-image: url('../img/icons/orders-outline.svg');
  }
  &.rule {
    background-image: url('../img/icons/rule_black_24dp.svg');
    color: #7F8FA4;
  }
  &.public_off {
    background-image: url('../img/icons/public_off_black_24dp.svg');
    color: #7F8FA4;
  }
  &.filters-exist {
    background-image: url('../img/icons/published_with_changes_black_24dp.svg');

    &:hover {
      background-image: url('../img/icons/published_with_changes_black_24dp_filled.svg');
    }
  }
  &.filter-exist-filled {
    background-image: url('../img/icons/published_with_changes_black_24dp_filled.svg');
  }
  &.sender-icon {
    background-image: url('../img/icons/senders.svg');
  }
  &.users-outline {
    background-image: url('../img/icons/users-outline.svg');
  }
  &.users-filled {
    background-image: url('../img/icons/users-outline.svg');
  }
  &.orders-filled {
    background-image: url('../img/icons/orders-filled.svg');
  }
  &.right {
    background-image: url('../img/icons/right.svg');
  }
  &.right-white {
    background-image: url('../img/icons/right-hover.svg');
  }
  &.round-email-menu {
    background-image: url('../img/icons/round-email-menu.svg');
  }
  &.last_update {
    // background-image: url($static_path + 'img/icons/update.svg');
    padding-bottom: 30px;
    margin-left: 4px;
  }
  &.round-email {
    background-image: url('../img/icons/round-email.svg');
  }
  &.search {
    background-image: url('../img/icons/search.svg');
  }
  &.tooltip-hover {
    background-image: url('../img/icons/tooltip-hover.svg');
  }
  &.tooltip-regular {
    background-image: url('../img/icons/tooltip-regular.svg');
  }
  &.upload {
    background-image: url('../img/icons/upload.svg');
  }
  &.upload-filled {
    background-image: url('../img/icons/upload-filled.svg');
  }
  &.download-filled {
    background-image: url('../img/icons/download-filled.svg');
  }
  &.white-arrow {
    background-image: url('../img/icons/white-arrow.svg');
    height: 21px;
  }
  &.grey-arrow {
    background-image: url('../img/icons/grey-arrow.svg');
    height: 21px;
    transition: .3s;
  }
}

.filter-btn {
  &.active {
    .icons {
      &.filter {
        background-image: url('../img/icons/close-hover.svg');
      }
    }
  }
}

.no-text-transform {
  text-transform: none !important;
}

.Toastify__toast-body {
  white-space: pre-line;

  a {
    text-decoration: none;
  }
}

.notice-links-block {
  position: relative;
  text-decoration: none!important;
  a {
    text-decoration: none!important;
    &:hover{
      text-decoration: underline!important;
    }
  }
  &:hover {
    .trash-button {
      display: inline-block;
    }

    .open-button {
      display: inline-block;
    }
  }

  .trash-button {
    position: absolute;
    right: 20px;
    top: 5px;

    display: none;
  }

  .open-button {
    position: absolute;
    top: 7px;
    right: 52px;

    display: none;
  }

  .link-block {
    display: inline-block;
    width: 97%;
  }

  .add-delete-icon {
    display: inline-block;
  }
}

.min-w-65 {
  min-width: 65px;
}

.mw-100 {
  max-width: 100% !important;
}

.w-100 {
  width: 100% !important;
}

a {
  color: #7f8fa4 !important;
}

.black-text {
  color: black !important;
}
