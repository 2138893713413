@keyframes ld-spin{
  0%{
    -webkit-transform:rotate(0);
    transform:rotate(0);
    animation-timing-function:cubic-bezier(.55,.055,.675,.19)
  }
  50%{
    -webkit-transform:rotate(180deg);
    transform:rotate(180deg);
    animation-timing-function:cubic-bezier(.215,.61,.355,1)
  }
  to{
    -webkit-transform:rotate(360deg);
    transform:rotate(360deg)
  }
}
@-webkit-keyframes ld-spin {
  0%{
    -webkit-transform:rotate(0);
    transform:rotate(0);
    animation-timing-function:cubic-bezier(.55,.055,.675,.19)
  }
  0%{
    -webkit-transform:rotate(180deg);
    transform:rotate(180deg);
    animation-timing-function:cubic-bezier(.215,.61,.355,1)
  }
  to{
    -webkit-transform:rotate(360deg);
    transform:rotate(360deg)
  }
}
