.react-modal {
  &.change-domain-email-block {
    width: 464px;
    background-color: $white;
    border-radius: 6px;
    padding: 32px;
    box-shadow: 0 2px 4px rgba($dark_blue, 0.08);
    position: relative;

    &.large {
      width: 734px;
    }

    &.xlarge {
      width: 1200px;
    }
  }
}

.change-domain-email-block-body {
  h2 {
    text-align: center;
    font-size: 24px;
    line-height: 34px;
    font-family: $font;
    margin: 0 0 28px 0;
    color: $dark_blue;
  }

  .form-group {
    margin-bottom: 32px;
  }

  .close-domain-email-block {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
  }
}