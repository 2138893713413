.landing-wrapper {
  aside {
    display: none;
  }
}

.landing-page{
  .landing-container {
    max-width: 1200px;
    padding: 0 20px;
    margin: 0 auto;
    width: 100%;
  }

  .landing-header {
    background: url($static_path + 'img/landing-header-bg.svg') center center no-repeat;
    background-size: cover;
    padding: 60px 80px;
    min-height: 790px;

    @media screen and (max-width: 767px) {
      min-height: 100vh;
      padding: 24px 24px;
    }
  }

  .landing-header-top {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .logo-landing {
      display: block;
      width: 147px;
      height: 63px;

      @media screen and (max-width: 767px) {
        width: 138px;
        height: 60px;
      }

      img {
        display: block;
        width: 100%;
      }
    }

    .account {
      border-bottom: 2px solid $white; 
      font: 24px/28px $font-semi;
      transition: all ease 0.2s;
      text-decoration: none;
      padding-bottom: 12px;
      align-items: center;
      color: $white;
      display: flex;

      @media screen and (max-width: 767px) {
        padding-bottom: 5px;
        line-height: 20px;
        font-size: 18px;
      }

      &:after {
        content:'';
        background: url($static_path + 'img/icons/arrow-right-white.svg') center center no-repeat;
        background-size: contain;
        margin-left: 18px;
        display: block;
        height: 15.8px;
        width: 15.8px;

        @media screen and (max-width: 767px) {
          margin-left: 11px;
        }
      }

      &:hover {
        border-bottom: 2px solid $tr;
        text-decoration: none;
      }
    }
  }

  .landing-header-content {
    max-width: 734px;
    margin: 0 auto;
    width: 100%;
    text-align: center;
    height: calc(100vh - 303px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 767px) {
      height: calc(100vh - 110px);
    }

    .title {
      font: 64px/67px $font-bold;
      margin-bottom: 22px;
      color: $white;

      @media screen and (max-width: 767px) {
        font-size: 36px;
        line-height: 36px;
        margin-bottom: 16px;
      }
    }

    .subtitle {
      display: block;
      font: 20px/26px $font;
      color: $white;
      margin-bottom: 43px;
      padding: 0 85px;

      @media screen and (max-width: 767px) {
        margin-bottom: 24px;
        line-height: 26px;
        font-size: 18px;
        padding: 0 31px;
      }
    }

    .btn {
      padding: 18px 65px;
      border-radius: 6px;
    }
  }
}

/* Parteners */
.parteners {
  text-align: center;
  box-shadow: 8px 0 6px rgba($gray_5b, 0.08);
  background-color: $white;
  padding-top: 38px;
  padding-bottom: 40px;
  position: relative;

  @media screen and (max-width: 767px) {
    display: none;
  }

  .parteners-title {
    font: 18px/22px $font;
    color: $dark_blue;
    margin: 0 0 28px 0;
  }

  .companies {
    max-width: 1166px;
    width: 100%;
    margin: 0 auto;

    img {
      display: block;
      width: 100%;
    }
  }
}

/* flex-items-grid */
.flex-items-grid {
  .flex-item-grid {
    @media screen and (min-width: 993px) {
     justify-content: space-between;
      align-items: center;
      display: flex;
    }

    &.switch {
      .flex-item {
        &:nth-child(1) {
          order: 1;

          @media screen and (min-width: 993px) {
            order: 2;
            margin-right: 0px; 
          }
        }

        &:nth-child(2) {
          order: 2;
          @media screen and (min-width: 993px) {
            order: 1;
            margin-right: 35px;
          }
        }
      }
    }

    .flex-item {
      max-width: 560px;
      width: 100%;

      @media screen and (max-width: 992px) {
       text-align: center;
       margin: 0 auto 81px auto;
      }

      &:first-child {
        margin-right: 35px;
        @media screen and (max-width: 992px) {
          margin: 0 auto 81px auto;
        }
      }

      &:last-child {
        margin-right: 0;

        @media screen and (max-width: 992px) {
         margin: 0 auto;
        }
      }

      .flex-item-title {
        font: 60px/67px $font-bold;
        color: $black;
        margin: 0 0 19px 0;

        @media screen and (max-width: 992px) {
         font-size: 36px;
         line-height: 40px;
         margin: 0 0 18px 0;
        }
      }

      .flex-item-content {
        p {
          font: 20px/30px $font;
          color: $dark_blue;
          margin-bottom: 30px;

          @media screen and (max-width: 992px) {
           font-size: 18px;
           line-height: 27px;
           margin-bottom: 26px;
          }

          strong {
            font-family: $font-bold;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

/* Introduction section*/
.intro-section {
  justify-content: space-between;
  background-color: $gray_fb;
  padding: 80px 0;
  box-shadow: 8px 0 6px rgba($gray_5b, 0.08);

  @media screen and (max-width: 992px) {
   padding: 37px 0;
  }

  .flex-item {
    .intro-card {
      box-shadow: 1px 4px 8px rgba($gray_5b, 0.08);
      padding: 54px 54px 43px 56px;
      background-color: $white;
      border-radius: 8px;
      position: relative;

      @media screen and (max-width: 992px) {
        padding: 54px 32px 36px 32px;
      }

      .card-copyright {
        position: absolute;
        background-color: $blue_5b;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
        display: flex;
        height: 72px;
        width: 72px;
        left: 56px;
        top: -36px;

        @media screen and (max-width: 992px) {
         transform: translateX(-50%);
         left: 50%;
        }

        img {
          display: block;
          max-width: 100%;
        }
      }

      .intro-card-title {
        font: 36px/54px $font-bold;
        color: $dark_33;
        margin: 0;
        border-bottom: 1px solid $gray_e8;
        padding-bottom: 25px;

        @media screen and (max-width: 992px) {
         font-size: 24px;
         line-height: 35px;
         padding-bottom: 27px;
        }

        span {
          border-bottom: 0.5px solid $dark_33;
          font: 36px/30px $font-bold;
          display: inline-block;

          @media screen and (max-width: 992px) {
           font-size: 24px;
           line-height: 20px;
          }
        }
      }

      .intro-card-items {
        padding-top: 29px;

        @media screen and (max-width: 992px) {
          text-align: center;
          padding-top: 24px;
        }
        
        @media screen and (min-width: 768px) {
          display: flex;
          justify-content: space-between;
        }

        .intro-card-item {
          @media screen and (max-width: 992px) {
            margin-bottom: 17px;
          }

          @media screen and (min-width: 768px) {
            max-width: 176px;
          }

          &:nth-child(2) {
            max-width: 225px;
            text-align: right; 
            width: 100%;

            @media screen and (max-width: 992px) {
              text-align: center;
              margin-bottom: 0;
              max-width: 100%;
            }
          }

          span {
            display: block;
            font: 16px/24px $font;
            color: $gray_78;
          }
        }
      }
    }
  }
}

/* register steps */
.register-steps {
  margin-bottom: 42px;
  background-color: $white;

  @media screen and (max-width: 992px) {
    padding-bottom: 50px;
    margin-bottom: 0;
  }

  &.is-sticky {
    padding-top: 76px;

    @media screen and (max-width: 992px) {
      padding-top: 0;
    }

    .register-steps-header {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
    }
  }
  
  .register-steps-header {
    box-shadow: 8px 0 6px rgba($gray_5b, 0.08);
    background-color: $white;
    display: flex;

     @media screen and (max-width: 992px) {
      display: none;
     }

    .landing-container{ 
      display:flex;
      align-items: center;
      justify-content: space-between;
      position: relative;

      &:after {
        content:'';
        position: absolute;
        bottom: -3px;
        left: 20px;
        background-color: $blue_0c;
        width: 260px;
        height: 6px;
        border-radius: 16px;
        box-shadow: 0px 4px 14px rgba($blue_0c, 0.40);
      }
    }

    a {
      font: 20px/76px $font;
      text-align: center;
      max-width: 260px;
      width: 100%;
      color: $black;

      &:hover {
        text-decoration: none;
      }

      span {
        display: inline-block;
        color: $blue_0c;
      }
    }
  }

  .flex-items-grid {
    padding-top: 55px;

    @media screen and (max-width: 992px) {
      padding-top: 40px;
    }

    .flex-item {
      margin-bottom: 83px;

      @media screen and (max-width: 992px) {
        text-align: left;
      }

      &:first-child {
        margin: 0 auto 38px auto;
      }

      .register-img {
        img {
          display: block;
          width: 100%;
        }
      }
    }

    .flex-item-grid {
      @media screen and (max-width: 992px) {
        margin-bottom: 80px;
      }

      @media screen and (max-width: 767px) {
        margin-bottom: 39px;
      }

      &:last-child {
        @media screen and (max-width: 992px) {
          margin-bottom: 0px;
        }
      }
    }
  }

  .register-item {
    .register-number {
      text-transform: uppercase;
      font: 20px/24px $font;
      color: $blue_0c;
      display: block;
      margin-bottom: 22px;
      letter-spacing: 1px;

      @media screen and (max-width: 767px) {
       font-size: 18px;
       line-height: 20px;
       margin-bottom: 16px;
      }
    }
  }
}

/* Extra info section */
.extra-info-section {
  background: url($static_path + 'img/extra-info-bg.jpg') center center no-repeat;
  justify-content: center;
  background-size: cover;
  align-items: center;
  height: 884px;
  display: flex;
  position: relative;

  @media screen and (max-width: 992px) {
    display: none;
  }

  &:after {
    content:'';
    width: 1160px;
    height: 718px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    background: url($static_path + 'img/extra-info-second-bg.jpg') center center no-repeat;
    background-size: contain;

    @media only screen and (min-width: 993px) and (max-width: 1300px) {
      width: 860px;
      height: 532px;
    }
  }

  .extra-info-content {
    max-width: 530px;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    position: relative;
    z-index: 1;

    .title {
      font: 64px/67px $font-bold;
      margin-bottom: 22px;
      color: $black;

      @media screen and (max-width: 767px) {
        font-size: 36px;
        line-height: 36px;
        margin-bottom: 16px;
      }
    }

    .subtitle {
      display: block;
      font: 20px/26px $font;
      color: $black;
      margin-bottom: 20px;

      @media screen and (max-width: 767px) {
        margin-bottom: 24px;
        line-height: 26px;
        font-size: 18px;
      }
    }

    .btn {
      padding: 18px 64px;
      border-radius: 6px;
    }
  }
}

/* landing price section */
.landing-price-section {
  background: $gray_f0 url($static_path + 'img/background-shapes-tr.png') center center no-repeat;
  background-size: cover;
  min-height: 908px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 992px) {
    min-height: auto;
    padding: 40px 20px;
  }

  .price-card {
    background: $white;
    box-shadow: 0px 6px 8px rgba(91, 103, 119, 0.0787477), 0px 2px 4px rgba(51, 60, 72, 0.08);
    max-width: 560px;
    width: 100%;
    margin: 0 auto;
    padding: 46px 48px 40px 48px;
    border-radius: 6px;

    @media screen and (max-width: 767px) {
       padding: 41px 24px 24px 24px;
    }

    .price-card-header {
      text-align: center;
      margin-bottom: 36px;

      @media screen and (max-width: 767px) {
        margin-bottom: 53px;
      }

      .title {
        font: 80px/84px $font-bold;
        color: $black;
        margin: 0 0 4px 0;

        @media screen and (max-width: 767px) {
          font-size: 60px;
          line-height: 64px;
          margin: 0 0 13px 0;
        }

        span {
          display: inline-block;
          font: 36px/40px $font;
          color: $gray_78;

          @media screen and (max-width: 767px) {
            font-size: 30px;
            line-height: 34px;
          }
        }
      }

      .subtitle {
        display: block;
        font: 14px/18px $font;
        color: $gray_78;
        margin: 0 0 4px 0;
        letter-spacing: 0.8px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .price-card-content {
      padding-left: 41px;
      margin-bottom: 44px;

      @media screen and (max-width: 767px) {
        margin: 0 auto 33px auto;
        padding-left: 17px;
        max-width: 300px;
        width: 100%;
      }

      .price-item {
        font: 24px/28px $font;
        color: $dark_blue;
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        @media screen and (max-width: 767px) {
          font-size: 16px;
          line-height: 20px;
        }

        &:last-child {
          margin-bottom: 0;
        }

        &:before {
          content:'';
          background: url($static_path + 'img/icons/checkmark-icon.svg') center center no-repeat;
          background-size: contain;
          width: 28px;
          height: 28px;
          margin-right: 23px;

          @media screen and (max-width: 767px) {
            margin-right: 16px;
          }
        }
      }
    }

    .btn {
      width: 100%;
      border-radius: 6px;
    }
  }
}

/* landing footer*/
.landing-footer {
  padding: 80px 0 135px 0;
  background-color: $white;

  @media screen and (max-width: 992px) {
    padding: 43px 0 35px 0;
  }

  .landing-footer-container {
    max-width: 1128px;
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;
    display: flex;

    @media screen and (max-width: 992px) {
      flex-direction: column;
    }
  }

  .landing-footer-item {
    max-width: 300px;
    width: 100%;

    @media screen and (max-width: 992px) {
      max-width: 100%;
      text-align: center;
      margin-bottom: 18px;
    }

    &:nth-child(1) {
      display: flex;
      justify-content: space-between;
      flex-direction: column;

      @media screen and (max-width: 992px) {
        order: 1;
      }
    }

    &:nth-child(2) {
      @media screen and (max-width: 992px) {
        order: 2;
      }
    }
    &:nth-child(3) {
      @media screen and (max-width: 992px) {
        order: 4;
      }

      .content {
        .info {
          p {
            @media screen and (max-width: 992px) {
              margin-bottom: 0;
            }
          }
        }
      }
    }
    &:nth-child(4) {
      @media screen and (max-width: 992px) {
        order: 3;
      }

      .content {
        .info {
          p {
            @media screen and (max-width: 992px) {
              color: $blue_0c;
              margin-bottom: 0;
            }
          }
        }
      }
    }

    &:last-child {
      flex: 1;
    }

    .logo-landing {
      width: 147px;
      height: 63px;

      @media screen and (max-width: 992px) {
        margin: 0 auto 41px auto;
      }

      img {
        width: 100%;
      }
    }

    .copyright {
      display: block;
      font: 20px/24px $font;
      color: $gray_78;

      @media screen and (max-width: 992px) {
        display: none;
      }
    }

    .content {
      .title {
        font: 20px/24px $font-bold;
        color: $dark_blue;
        margin: 0 0 18px 0;

        @media screen and (max-width: 992px) {
          font-size: 18px;
          line-height: 20px;
        }
      }

      .info {
        p {
          margin-bottom: 20px;

          &.p-105 {
            padding-right: 105px;

            @media screen and (max-width: 1200px) {
              padding: 0px;
            }

            @media screen and (max-width: 992px) {
              padding: 0px;
              max-width: 220px;
              width: 100%;
              margin: 0 auto 20px auto;
            }
          }
        }
        p, 
        span,
        a {
          font: 20px/30px $font;
          color: $dark_blue;
          display: block;

          @media screen and (max-width: 992px) {
            font-size: 18px;
            line-height: 28px;
          }
        }

        span,
        a {
          &.color-blue {
            color: $blue_0c;
          }
        }
      }
    }
  }
}













