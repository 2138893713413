.copyright-info {
  display: flex;

  #cp-item-filter {
    width: 100% !important;
  }

  .copyright-item {
    display: flex;
    width: 100%;
    overflow: hidden;
    
    &:nth-child(1) {
      width: 468px;
    }

    &.copyright-no-flex {
      display: block;
    }

    .inside-copyright-item {
      display: flex;
    }

    .forward-block {
      display: flex;
      padding-top: 8px;
    }

    .description-block {
      span, strong {
        min-height: 24px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: pre;
      }

      // span.notice_received_by {
      //   display: inline-block;
      //   white-space: initial;        
      // }
    }

    &:nth-child(2) {
      flex: 1;

      .forward-block {
        .title-block {
          margin-right: 65px;
        }
      }

      .title-block {
        margin-right: 47px;
      }
    }

    .title-block {
      margin-right: 53px;

        div {
        .gray_primary {
          display: inline-block;
        }
        
        margin-bottom: 8px;
        
        span.is-link {
          display: inline-block;
          max-width: 68%;
        }
      }

      span {
        white-space: nowrap;
      }
    }

    span,
    strong {
      display: block;
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  } 
}