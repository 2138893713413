.order-form-url {
  width: 100%;
}

.form-add-domain-switch-block {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 25px;

  .add-domain.add-domain-form {
    margin-right: 0;
    align-items: flex-end;

    .switch-block-right {
      .btn.loading:after {
        left: 34px;
      }
    }
  }

  &.switch {
    .switch-block-left {
      &.add-domain-form {
        width: 100%;
      }

      .domain-search {
        display: none;
      }

      .add-domain {
        display: flex;
      }
    }

    .switch-block-right {
      .close-add-domain {
        display: block;
        margin-left: 24px;
      }

      .btn {
        .icons {
          &.add {
            background-image: url($static_path + 'img/icons/add-domain.svg');
          }
        }
      }
    }
  }

  &.second-switch-block {
    .switch-block-left {
      width: 890px;

      .add-domain {
        .form-group {
          width: 429px;

          &:last-child {
            width: 429px;
          }
        }
      }
    }

    .switch-block-right {
      width: 216px;
      padding-right:0;
      justify-content: flex-end;
    }
  }

  .switch-block-left {
    width: 821px;

    .form-group {
      margin-right: 32px;

      &:last-child {
        margin-right: 0;
      }
    }

    .domain-search {
      .form-group {
        &.w-553 {
          @media screen and (max-width: 1479px) {
            width: 67.357% !important;
          }
        }
      }
    }
  }

  .switch-block-right {
    width: 253px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 12px;

    &.block-right {
      justify-content: flex-end;
    }

    .close-add-domain {
      display: none;
    }
  }
}

.domain-search {
  display: flex;
  align-items: flex-end;
}

.add-domain {
  display: none;
  align-items: center;
  justify-content: space-between;
  margin-right: 32px;

  .form-group {
    width: 429px;
    @media screen and (max-width: 1479px) {
      width: 52.255%;
    }

    &:last-child {
      width: 360px;
      @media screen and (max-width: 1479px) {
        width: 43.85%;
      }
    }
  }
}
