.credit-card-form {
  background-color: $white;
  box-shadow: 0 0 6px 2px rgba($dark_blue, 0.05);
  border-radius: 6px;
  padding: 34px 40px 40px 40px;

  .credit-card-title {
    font-size: 24px;
    line-height: 29px;
    font-family: $font-semi;
    color: $dark_blue;
    display: block;
    margin-bottom: 37px;
  }

  .credit-card-img-block {
    margin-bottom: 38px;
    img {
      display: block;
    }
  }
}