.payment-page {
  .card-block.order-payment-method-container {
    padding-right: 31px !important;
    padding-left: 31px !important;
  }

  .payment-introduction-item {
    .title {
      display: block;
    }

    .status {
      display: inline-block;
    }
  }

  .payment-method-container{ 
    .payment-title {
      display: none;
    }
  }

  .payment-introduction-card {
    &.mb-40 {
      margin-bottom: 16px !important;
    }
  }
}


.payment-introduction {
  display: flex;

  .payment-introduction-item {
    &:nth-child(1) {
      width: 260px;
    }
    &:nth-child(2) {
      width: 150px;
    }
    &:nth-child(3) {
      flex:1;
    }
    &:nth-child(4) {
      width: 196px;
    }
    span {
      display: block;
    }

    .title {
      font-size: 16px;
      line-height: 24px;
      font-family: $font;
      color: $gray_primary;
      margin-bottom: 8px;
    }

    .description {
      font-size: 24px;
      line-height: 32px;
      font-family: $font;
      color: $dark_blue;
    }
  }
}

.payment-method-container {
  .payment-title {
    font-size: 32px;
    line-height: 38px;
    color: $dark_blue;
    font-family: $font-semi;
    margin: 0 0 40px 0;
    text-align: center;
    display: block;
  }

  .payment-method-block {
    display: flex;

    .payment-method-wrapper {
      max-width: 465px;
      width: 100%;
      border-radius: 6px;

      &:nth-child(1) {
        margin-right: 30px;
        background-color: rgba($gray_secondary, 0.30);
        padding: 34px 40px 40px 40px;
      }

      .payment-methid-title {
        font-size: 24px;
        line-height: 29px;
        font-family: $font-semi;
        color: $dark_blue;
        margin-bottom: 29px;
        display: block;
      }
    }
  }
}

/* new payment page not popup*/
.payment-page {
  .payment-method-container {
    .payment-method-block {
      justify-content: center;

      .payment-method-wrapper {
        max-width: 100%;
      }
    }
  }

  .credit-card-form {
    .form-group {
      &.w-178 {
        width: 45%;
      }

      &.w-188 {
        width: 55%;
      }
    }
  }
}










