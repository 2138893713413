.react-pdf__Document {
  // background-color: #efefef;
  padding-bottom: 42px;
  text-align: center;
  padding-top: 42px;
  max-height: 55vh;
  @media screen and (min-height: 1648px) {
    max-height: 30vh;
  }
  .react-pdf__message {
    max-height: 49vh;
    display: flex;
    flex-direction: column;
    .email-hide-css{
      display: none;
    }
    @media screen and (min-height: 1140px) {
      max-height: 31vh;
    }
    br {
      display: none;

      &:nth-child(2n) {
        display: block;
      }
    }
  }

  .page-item {
    &:last-child {
      .pdf-page-number {
        margin-bottom: 0;
      }
    }
  }

  &.email-document {
    text-align: left;
    overflow-x: auto;
    //white-space: pre-line;
    p {
      white-space: initial;
    }

    .email-content-wrapper {
      max-width: 80%;


    }

    // .react-pdf__message {
    //   white-space: initial;
    // }
    .email_link_wrapper {
      padding-left: 20px;
    }
    .email_link_wrapper:last-child {
      padding-left: 0;
    }
  }

  canvas {
    margin: 0 auto;
  }

  .pdf-page-number {
    text-align: center;
    margin-top: -40px;
    display: block;
    position: relative;
    z-index: 1;
    margin-bottom: 40px;
  }
}
