.form-group {
  padding-left: 0;
  margin-bottom: 25px;

  &.search-input {
    flex: 1;
  }

  & ~ .btn {
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }
  }


  &.form-control-small {
    font-size: 14px;
    min-height: 32px;

    .react-select-container {
      .react-select__control {
        min-height: 32px;

        .react-select__value-container {
          &.react-select__value-container--is-multi {
            min-height: 30px;
            padding: 0px 33px 0px 16px
          }
        }
      }
    }
  }

  .radio-input {
    width: 106px;
    margin: 0;
    position: relative;
    height: 40px;

    &:nth-child(1),
    &:nth-child(2) {
      label {
        border-right: none;
      }
    }

    &:nth-child(1) {
      label {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
      }
    }

    &:nth-child(3) {
      label {
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
      }
    }

    input {
      outline: none;
      border: none;
      width: 100%;
      height: 100%;
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
    }

    label {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      margin: 0;
      border: 1px solid $gray_secondary;
      color: $gray_primary;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      span {
        font-size: 16px;
        font-family: $font-semi;
        text-transform: capitalize;
        display: block;
      }
    }

    input[type="radio"]:checked+label {
      background-color: $gray_primary;
      border-color: $gray_primary;
      color: $white;
    }
  }

  &.with-icon {
    position: relative;

    .icons {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 40px;
      height: 40px;
      background-position: center center;
    }
  }

  &.full-input {
    width: 100%;
  }

  &.form-check {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    margin-bottom: 24px;
  }
  &.form-group-lg{
    .select2-results__option{
      font-size: 16px;
      line-height:40px;
    }
  }
  &.form-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  label {
    color: $dark_blue;
    font-size: 12px;
    line-height: 14px;
    display: block;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    font-family: $font-semi;
    margin-bottom: 7px;
  }
  .checkbox {
    .form-check-label {
      color: $gray_primary;
      text-transform: none;
      font-family: $font;
      display: flex;
      .label-text{
        .label-help-text{
          display: block;
          font-size:14px;
          line-height: 24px;
          padding-top: 4px;
          color: #7f8fa4;
        }
      }
    }
  }

  &.second-type-shadow {
    .form-control {
      box-shadow: inset 0 1px 2px rgba($dark_blue, .1);
    }
  }

  textarea {
    resize: none;
    min-height: 80px;

    &.min-height-140 {
      min-height: 140px;
    }
  }

  .form-control {
    border: 1px solid $gray_secondary;
    box-shadow: inset 0 1px 2px rgba($dark_blue, .1);
    color: $dark_blue;
    font-size: 16px;
    line-height: 24px;
    padding: 4px 16px 2px;
    height: auto;

    &::-webkit-input-placeholder {
      color: $gray_secondary;
    }

    &::-moz-placeholder {
      color: $gray_secondary;
    }

    &::-ms-input-placeholder {
      color: $gray_secondary;
    }

    &::-moz-placeholder {
      color: $gray_secondary;
    }

    &:focus {
      border-color: $gray_primary;
      color: $dark_blue;
    }

    &:disabled {
      cursor: not-allowed;
      border-color: $gray_light_v2;
      background: $gray_light_v2;
      box-shadow: none;
      color: $dark_blue;
    }
    &.form-control-lg {
      padding-top: 8px;
      padding-bottom: 6px;
      border-radius: 3px;
    }

    &.is-invalid {
      border-color: $red;
      box-shadow: none;
    }
  }

  .input-row {
    position: relative;
    display: block;

    &.has-error {
      .invalid-feedback {
        display: block;
      }
    }
  }

  .invalid-feedback {
    position: absolute;
    width: 100%;
      font-family: $font-semi;
      font-size: 12px;
      line-height: 14px;
      color: $red;
      margin-top: 2px;
      margin-bottom: 7px;
    }
    .form-text {
      color: $red;
      margin-top: 2px;
      font-size: 12px;
      line-height: 14px;
    }
}

.missive-column-auto {
  padding: 0px 8px 0px 14px;
  font-size: 24px;
  line-height: 0;

  &.text-e {
    color: grey;
  }
}


div.filter-field {
  width: 30%;
  padding-left: 10px;
  display: inline-block;
}


div.filter-field-action {
  position: absolute;
  left: 93%;
  bottom: 13%;
  // width: 30px;
  // height: 30px;
  // border-radius: 5px;
  // // border-color: black;
  // border: 1px solid black;
}

.filter-item {
  padding-left: 20px;
  padding-top: 15px;
  position: relative;
}

// Form React Select input
.react-select-container {

  &.inline-select {
    display: inline-block;
    width: 35%;
  }

  &.create_contact {
    .react-select__menu-list {
      .react-select__option {
        position: relative;
        min-height: 40px;

        &:last-child {
          border-top: 1px solid $gray_primary;
        }
      }
    }
  }

  &.menu-dropup {
    .react-select__menu {
     @media only screen and (max-height: 900px) {
        top: auto;
        bottom: 100%;
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 0px;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        border-bottom: none;
        border-top: 1px solid $gray_primary;
        margin-top: 0;
        margin-bottom: -3px;
      }

      @media only screen and (max-height: 860px) {
        top: auto;
        bottom: 100%;
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 0px;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        border-bottom: none;
        border-top: 1px solid $gray_primary;
        margin-top: 0;
        margin-bottom: -3px;
      }

      .react-select__menu-list {
        max-height: 160px;

        @media only screen and (max-height: 900px) {
          // max-height: 160px;
        }

        @media only screen and (max-height: 860px) {
          // max-height: 120px;
        }
      }
    }

    .react-select__control--is-focused {
      @media only screen and (max-height: 900px) {
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 3px;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
      }

      @media only screen and (max-height: 860px) {
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 3px;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
      }
    }
  }

  &:not(.react-select-multiselect) {
    .react-select__value-container {
      .css-1g6gooi,
      .react-select__input {
         width: 100% !important;
      }

      .react-select__input {
        input {
           width: 100% !important;
        }
      }
    }
  }

  &.react-select-multiselect {
    .react-select__indicators {
      max-height: 38px;
    }

    .react-select__value-container {
      .react-select__input {
        input {
          padding-left: 4px !important;
        }
      }
    }
  }

  .react-select__value-container {
    padding: 1px 5px 1px 14px;
    min-height: 38px;
    display: flex;
    align-items: center;

    &.react-select__value-container--is-multi {
      padding: 5px 5px 5px 4px;
    }
  }


  .react-select__control {
    color: $white;
    border-color: $gray_secondary;
    border-radius: 3px;
    box-shadow: inset 0 1px 2px rgba(51,60,72,0.1);
    min-height: 40px;
    // display: block;

    &:hover {
      border-color: $gray_secondary;
      color: $white;
    }
  }

  .react-select__placeholder,
  .css-1g6gooi {
    margin: 0;
  }

  .react-select__placeholder {
    color: $gray_secondary;
  }

  .react-select__control--is-focused {
    border-color: $gray_primary;
    color: $dark_blue;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;

    &:hover {
      border-color: $gray_primary;
      color: $dark_blue;
    }

    .react-select__dropdown-indicator {
      background-image: url('../img/icons/dropdown-up.svg');
    }
  }

  .react-select__menu {
    margin: 0;
    border-right: 1px solid $gray_primary;
    border-bottom: 1px solid $gray_primary;
    border-left: 1px solid $gray_primary;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    margin-top: -2px;
    box-shadow: none;

    ::-webkit-scrollbar {
      width: 22px;
      border-radius: 0px;
    }

    ::-webkit-scrollbar-track{
      background: $tr;
      border-radius: 0px;
    }

    ::-webkit-scrollbar-thumb {
      width: 7px;
      background: $gray_secondary;
      border:7px solid $white;
      border-radius: 15px;
    }
  }

  .react-select__option {
    font-size: 16px;
    line-height: 24px;
    font-family: $font;
    padding: 8px 15px;
  }

  .add-element {
    background-color: $gray_light_v2;
    background-image: url('../img/icons/add-black.svg');
    background-repeat: no-repeat;
    background-position: 16px 8px;
    padding-left: 48px;
    position: absolute;
    width: 100%;
    font-size: 14px;
    line-height: 17px;
    border-bottom-left-radius: 4px;
    font-family: $font;
    padding-top: 12px;
    padding-bottom: 2px;
    padding-right: 0;
    margin-right: 0;
    right: -1px;
    left: -1px;
    bottom: 0;
    top: 0;
  }

  .react-select__menu-list {
    padding-top: 0;
    padding-bottom: 0;
    border-radius: 4px;
  }

  .react-select__option--is-focused,
  .react-select__option--is-selected {
    background-color: $gray_light_v2;
    color: $dark_blue;
    cursor: pointer;

    &:last-child {
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
    }
  }

  .react-select__indicator-separator{
    display: none !important;
  }

  .react-select__clear-indicator {
    padding: 0;
    width: 24px;
    height: 100%;
    background-image: url('../img/icons/close-hover.svg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 19px;
    cursor: pointer;

    svg {
      display: none;
    }
  }

  .react-select__dropdown-indicator  {
    width: 40px;
    height: 100%;
    background-image: url('../img/icons/dropdown-down.svg');
    background-position: center center;
    background-repeat: no-repeat;

    svg {
      display: none;
    }
  }

  .react-select__multi-value {
    margin: 2px 0px 2px 4px;
    background-color: $blue_hover;
    border-radius: 3px;
    padding: 0 5px 0 9px;


    .react-select__multi-value__label {
      color: $white;
      font-size: 14px;
      padding: 0;
      margin-right: 5px;
    }

    .react-select__multi-value__remove {
      padding: 0;
      color: $white;

      &:hover {
        background-color: transparent;
        color: $white;
      }
    }
  }
}

.input-field {
  &.is-invalid {
    .react-select-container {
      .react-select__control {
        border-color: $red;
      }
    }

    .invalid-feedback {
      display: block;
    }
  }
}

/* princing input select*/

.card-block.payment-introduction-card.pricing-input-select-container {
  padding-left: 31px !important;
  padding-right: 31px !important;
}

.pricing-input-select {
  display: flex;

  .input-selection {
    position: relative;
    

    &:first-child {
      width: calc(51% - 5px);
      margin-right: 15px;
    }

    &:last-child {
      margin-left: 15px;
      flex: 1;
    }
  }

  label {
    font-family: $font-semi;
    margin-bottom: 0;
    border-radius: 3px;
    font-weight: normal;
    cursor: pointer;
    color: $dark_33;
    background: #fff;
    border: 1px solid #CCD2DB;
    padding:11px 20px 9px 21px;
    display: block;
    line-height: 32px;
    font-size: 24px;
  }

  input {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
  }

  input[type="radio"]:checked+label {
    background-color: $green;
    border-color: $green;
    color: $white;
  }
}
