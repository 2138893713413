.company-section-container {
  min-height: calc(100vh - 64px);
  position: relative;

  &.has-items {
    margin-bottom: 38px;
  }
}

.order-banner-list-block {
  position: relative;

  .icons {
    &.close-regular {
      position: absolute;
      cursor: pointer;
      z-index: 100;
      right: 32px;
      top: 32px;
    }
  } 

  .btn.btn-green {
    min-width: 160px;

    &.loading {
      &:after {
        margin-top: -8px;
        height: 16px;
        right: 8px;
        width: 16px;
      }
    }
  }
}