.notice-info-buttons-container {
  padding: 0 19px;
  margin-top: 10px;
}
.order-company-info-container {
  display: flex;

  .order-company-item {
    width: 50%;
    // &:nth-child(1) {
    //   width: 268px;
    // }

    &:nth-child(1) {
      // width: 299px;
      padding-right: 25px;
    }

    //  &:nth-child(2) {
    //   width: 415px;
    // }
  }

  .pay-now-btn {
    margin-bottom: 15px;
  }
}
