.react-tagsinput {
  box-shadow: inset 0 1px 2px rgba(51,60,72,0.1);
  background-color: #fff;
  border: 1px solid $gray_secondary;
  border-radius: 3px;
  overflow: hidden;
  padding-left: 8px;
  padding-top: 7px;
  min-height: 40px;
  color: $white;
}

.react-tagsinput--focused {
  border-color: $gray_primary;
  color: $dark_blue;
}

.react-tagsinput-tag {
  display: inline-block;
  font-family: sans-serif;
  color: $white;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 4px;
  margin-right: 4px;
  background-color: $blue_hover;
  border-radius: 3px;
  padding: 0 13px 0 9px;
  border: 0 none;
}

.react-tagsinput-remove {
  cursor: pointer;
  font-weight: bold;
  position: absolute;
}

.react-tagsinput-tag a::before {
  content: "";
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pjxzdmcgdmlld0JveD0iMCAwIDMyIDMyIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxkZWZzPjxzdHlsZT4uY2xzLTF7ZmlsbDojZmZmO3N0cm9rZTojZmZmO3N0cm9rZS1saW5lY2FwOnJvdW5kO3N0cm9rZS1saW5lam9pbjpyb3VuZDtzdHJva2Utd2lkdGg6MnB4O308L3N0eWxlPjwvZGVmcz48dGl0bGUvPjxnIGlkPSJjcm9zcyI+PGxpbmUgY2xhc3M9ImNscy0xIiB4MT0iNyIgeDI9IjI1IiB5MT0iNyIgeTI9IjI1Ii8+PGxpbmUgY2xhc3M9ImNscy0xIiB4MT0iNyIgeDI9IjI1IiB5MT0iMjUiIHkyPSI3Ii8+PC9nPjwvc3ZnPg==);
  position: absolute;
  display: block;
  width: 10px;
  height: 10px;
  top: 8px;
  right: -11px;
}

.react-tagsinput-input {
  background: transparent;
  border: 0;
  font-family: sans-serif;
  font-weight: 400;
  margin-bottom: 4px;
  outline: none;
  padding: 0 5px;
  //width: 80px;
  color: $dark_blue;
  font-size: 16px;
  line-height: 24px;

  input {
    border: 0 none;
  }
}
