.add-new-company-form {
  .extra-item {
    margin-bottom: 31px;

    &:last-child {
      margin-bottom: 0;
    }

    .title {
      display: block;
      font-size: 18px;
      line-height: 24px;
      font-family: $font-semi;
      color: $dark_blue;
      margin-bottom: 17px;
    }

    p {
      margin: 0 0 24px 0;
      font-size: 16px;
      line-height: 24px;
      color: $gray_primary;

      &:last-child {
        margin: 0;
      }
    }
  }

  /*Step 1*/
  .new-company-step-1 {
    display: flex;
    padding-top: 50px;

    .invalid-feedback {
      display: block;
    }

    .new-company-input-block {
      width: 480px;
      margin-right: 80px;

      .new-company-input-block-bottom {
        display: flex;
        margin-bottom: 0px;

        .form-group {
          width: 328px;
          margin-right: 32px;

          &:last-child {
            width: 120px;
            margin-right: 0;
          }
        }
      }

      .form-group {
        &.with-icon {
          margin-bottom: 32px;
        }
      }
    }

    .extra-info-block {
      width: 400px;
    }
  }

  /*Step 2*/
  .new-company-step-2 {
    padding-top: 50px;

    .new-company-add-domain-block {
      display: flex;
      align-items: flex-end;
      margin-bottom: 25px;

      .form-group {
        width: 356px;
        margin-right: 32px;
        margin-bottom: 0;
      }
    }

    .extra-item {
      margin-bottom: 54px;
    }

    .table {
      margin-bottom: 33px;
    }

    .btn-block {
      justify-content: flex-end;
      display: flex;

      .btn-green {
        justify-content: center;
        align-items: center;
        display: flex;
      }

      .btn {
        margin-right: 20px;

        &:first-child {
          padding: 17px 21px 17px 20px;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  /*Step 3*/
  .new-company-step-3 {
    padding-top: 50px;

    .new-company-ste-3-header {
      margin-bottom: 31px;

      h1 {
        display: flex;
        align-items: center;
        margin: 0 0 33px 0;

        strong {
          display: block;
          margin-right: 24px;
        }
      }

      p {
        color: $gray_primary;
        margin: 0;

        a {
          color: $blue;
        }
      }
    }

    .new-company-step-3-info {
      border-top: 1px solid $gray_secondary;
      border-bottom: 1px solid $gray_secondary;
      padding: 32px 0;
      display: flex;
      margin-bottom: 32px;

      .step-3-info-item {

        &:nth-child(1) {
          width: 388px;
        }

        &:nth-child(2) {
          width: 203px;
        }

        &:nth-child(3) {
          width: 252px;
        }
        .title {
          color: $gray_primary;
          display: block;
          margin-bottom: 7px;
        }

        .sub-title {
          font-size: 24px;
          line-height: 32px;
          display: block;
          color: $dark_blue;
          margin-bottom: 9px;
        }

        p {
          margin: 0;
        }
      }
    }

    .table {
      margin-bottom: 34px;
    }

    .btn-block {
      width: 400px;
      margin-left: auto;
    }
  }
}



















