.order-payment-method {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  border: 1px solid #f7981c;
  box-shadow: 0 2px 4px 0 rgba(51, 60, 72, 0.08);
  border-radius: 6px;
  padding: 32px;
  background: #fff;

  &.short-version {
    max-width: 479px;
    
    .form-group:last-child {
      border-right: 0 none;
    }
  }

  .form-group {
    width:228px;
    margin-bottom: 0;
    border-right:1px solid #efefef;

    &:not(:first-child){
      padding-left: 32px;

    }
    
    &:nth-child(2){
      width:303px;
    }

    &:last-child {
      border-right: none;
    }

    &:nth-child(3){
      border-right: none;
      flex: 1;

      .form-check-label {
        .icons {
          
          @media screen and (max-width: 1377px) {
            flex: 0 0 11%;
          }

          @media screen and (max-width: 1350px) {
            flex: 0 0 12%;
          }
        }
      }
    }

    &.disable {
      opacity: 0.2;
      cursor: not-allowed;
      position: relative;

      &:after {
        content:'';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        background-color: transparent;
        cursor: not-allowed;
      }

      .checkbox {
        label {
          cursor: not-allowed;
        }
      }
    }
  }

  .payment_method_item {
    padding-bottom: 0;
    padding-top: 4px;
    min-height: 28px;

    &.loading {
      position: relative;

      img, span {
        opacity: 0;
      }

      &:after {
        border-color: $orange $orange $orange transparent;
        -webkit-animation:ld-spin 1s infinite;
        animation:ld-spin 1s infinite;
        border-radius: 50%;
        border-style: solid;
        border-width: 3px;
        display: block;
        position: absolute;
        margin-top: -10px;
        height: 20px;
        content: '';
        width: 20px;
        top: 50%;
        left: 0;
      }

      .icons {
        opacity: 0;
      }

      &.btn-red {
        &:after {
          border-color: $red $red $red transparent;
        }
      }
    }
  }

  .checkbox{
    .label-text{
     font-size: 18px;
     font-family:$font-semi;
    }

    label {
      .checkmark-regular {
        margin-right: 16px;
        background-position: 0 -1px;
      }
    }
    input:checked+label .checkmark-regular,
    &.checked .checkmark-regular {
      background-image: url($static_path + 'img/icons/checkmark-filled-orange.svg');
    }
    input:checked+label,
    &.checked label {
      color: #333c48;
    }
  }
}
