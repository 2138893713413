.react-modal-overlay {
  background-color: rgba($dark_blue, 0.3);
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px;
  max-height: 100vh;
  overflow: auto;

  &.modal-edit-member-container {
    @media screen and (max-height: 697px) {
      display: block;
    }
  }

  &.d-block {
    display: block !important;
  }
}

.react-modal {
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  border: 0px solid transparent;
  background: rgb(255, 255, 255);
  border-radius: 0px;
  outline: none;
  padding: 0px;
  margin: 0 auto;

}

.popup-title {
  font-size: 24px;
  line-height: 32px;
  color: $dark_blue;
  font-family: $font;
}

.close-popup {
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;

  &:hover {
    &.close-regular {
      background-image: url('../../img/icons/close-hover.svg');
    }
  }
}

.ReactModal__Content,
.ReactModal__Overlay {
  transition: opacity 450ms;
  opacity: 0;
}

.ReactModal__Content--after-open,
.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Content--before-close,
.ReactModal__Overlay--before-close {
  opacity: 0;
}