.avatar-row {
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  display: block;

  .avatar-placeholder {
    background-color: $gray_primary;
    text-transform: uppercase;
    justify-content: center;
    font-family: $font-semi;
    align-items: center;
    position: absolute;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    color: #fff;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;

    .email {
      font-family: $font-semi;
      font-size: 25px;
    }
  }

  img {
    border-radius: 50%;
    max-width: 100%;
    display: block;
  }

  &.normal {
    width: 40px;
    height: 40px;
  }

  &.big {
    width: 80px;
    height: 80px;

    .avatar-placeholder {
      font-size: 29px;
    }
  }

  &.small {
    width: 24px;
    height: 24px;

    .avatar-placeholder {
      font-size: 10px;
    }

    .email {
      font-size: 15px;
    }
  }
}
