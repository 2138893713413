.user-profile {
  display: flex;
  align-items: center;


  .user-profile-block {
    div.status {
      margin-top: 10px;
    }
  }

  &.medium-image {
    .user-img-block {
      .user-img {
        margin-right: 16px;
        height: 40px;
        width: 40px;
      }
    }
  }

  .user-img-block {
    position: relative;
    cursor: pointer;

    &:hover {
      .user-profile-hover {
        opacity: 1;
        visibility: visible;
      }
    }

    .user-img {
      width: 80px;
      height: 80px;
      display: block;
      margin-right: 24px;
    }

    .user-profile-hover {
      position: absolute;
      top: 0;
      left: 0;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      background-color: rgba(51, 60, 72, 0.88);
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;
      visibility: hidden;
      transition: all 0.5s ease;
    }
  }

  .card-title {
    margin-bottom: 5px!important;
  }
}

.profile-actions {
  button {
    min-width: 193px;
  }
}