.no-data-container {
  display: flex;
  align-items: center;
  justify-content: center;

  &.users {
    min-height: 305px;

    .icons {
      &.users-not-found-icon {
        width: 34px;
        height: 40px;
        display: block;
        margin: 0 auto 35px auto;
      }
    }
  }

  &.notices {
    min-height: 368px;

    .no-data-content {
      padding-top: 31px;
    }

    .icons {
      &.notice-not-found-icon {
        width: 44px;
        height: 40px;
        display: block;
        margin: 0 auto 35px auto;
      }
    }
  }

  &.domain {
    min-height: 305px;

    .icons {
      &.domain-not-found-icon {
        width: 38px;
        height: 40px;
        display: block;
        margin: 0 auto 35px auto;
      }
    }
  }

  &.orders {
    min-height: 347px;

    .no-data-content {
      padding-top: 37px;
    }

    .icons {
      &.order-not-found-icon {
        width: 49px;
        height: 39px;
        display: block;
        margin: 0 auto 35px auto;
      }
    }
  }

  .no-data-content {
    text-align: center;

    .title {
      font-size: 24px;
      line-height: 29px;
      display: block;
      margin-bottom: 14px;
    }

    p {
      margin: 0 0 31px 0;
      font-size: 16px;
      line-height: 24px;
      color: $gray_primary;
      display: block;
    }

    .btn {
      margin: 0 auto;
      padding: 8px 31px 6px 31px;
      display: flex;
      align-items: center;

      .icons {
        margin-right: 8px;
      }
    }
  }
}