.notice-page {
  min-height: 505px;

  .link-black {
    color: #000 !important;
    text-decoration: none;
  }

  .inline-domains {
    display: inline !important;
    padding-right: 3px !important;
    margin-bottom: 8px !important;
  }

  .notice-header {
    margin-bottom: 33px;
    h1 {
      margin-bottom: 0;
    }
  }

  .description-block {
    max-width: 68%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .is-link {
    color: $black;
    width: 100%;
    overflow:hidden;
    text-overflow: ellipsis;
    white-space: pre;
    color: #7f8fa4 !important;

    &.black-text {
      color: black !important;
      text-decoration: none;
    }

    &.loading {
      position: relative;

      &:after {
        border-color: $gray_primary $gray_primary $gray_primary transparent;
        -webkit-animation: ld-spin 1s infinite;
        animation: ld-spin 1s infinite;
        border-radius: 50%;
        border-style: solid;
        border-width: 3px;
        display: block;
        position: absolute;
        margin-top: -10px;
        height: 17px;
        content: '';
        width: 17px;
        right: -25px;
        top: 50%;
      }
    }

    &:hover {
      text-decoration: underline;
      color: $dark_blue;
    }
  }

  .message-row {
    white-space: nowrap;
    overflow: hidden;
    display: block;
    max-width: 100%;
    text-overflow: ellipsis;
    min-height: 18px;
    margin-bottom: 0;
    line-height: 23px;
  }

  .btn {
    &.arrow-btn {
      position: relative;
      .icons {
        position: absolute;
        top: calc(50% - 12px);
        transition: right .2s;

        &:last-child {
          left: auto;
          right: 32px;
        }
      }

      &:hover {
        .icons {
          left: auto;
          right: 16px;
        }
      }
    }
  }
}
