.react-modal {
  &.modal-notice-source {
    width: 770px;
    background-color: $white;
    border-radius: 6px;
    padding: 32px;
    box-shadow: 0 2px 4px rgba($dark_blue, 0.08);
    position: relative;
  }
}

.notice-source-popup-body {
  .notice-source-title {
    font-size: 24px;
    line-height: 32px;
    color: $dark_blue;
    text-align: center;
    margin: 0 0 28px 0;
    font-family: $font;
  }

  .source-info {
    border: 1px solid $gray_secondary;
    border-radius: 3px;
    box-shadow: 0 1px 2px rgba($dark_blue, 0.1);
    font-size: 16px;
    line-height: 40px;
    color: $dark_blue;
    font-family: $font;
    padding: 0 10px 0 16px;
    max-height: 360px;
    min-height: 360px;
    overflow-y: auto;
    overflow-x: hidden;


    &.source-empty-info {
      justify-content: center;
      align-items: center;
      display: flex;
    }
  }
}