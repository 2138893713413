.add-order-notice-form {
  width: 100%;
}

.notice-files-block {
  .files-added-block {
    padding-top: 0;
  }
}

.add-notice-form {
  .section-item {
    border-bottom: 1px solid $gray_light;
    // margin-bottom: 15px;

    &.notice-item {
      border-bottom: none !important;
    }

    .checked-header {
      font-family: $font-semi;
      cursor: pointer;
    }

    .section-item-body {
      padding-left: 30px;
    }
  }

  .grey {
    padding-top: 24px;
    border: 0;
    margin: 0;
    .section-item-body{
      background-color: $gray_light_v2;
      border-radius: 3px;
    }
  }

  .add-notice-block {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .add-notice-item {
      width: 326px;
      margin-bottom: 24px;

      .form-group {
        margin-bottom: 0;
      }

      &.full-notice-input {
        width: 100%;
      }

      &.small-notice-input {
        display: flex;
        justify-content: space-between;
        .form-group {
          margin-right: 20px;

          &:last-child {
            margin-right: 0;
          }
        }
      }

      .form-group {
        &.city-filed {
          width: 121px;
        }

        &.state-filed {
          width: 71px;
        }

        &.city-postalcode {
          width: 95px;
        }
      }
    }
  }
  .drop-file-container .files-added-block {
    padding-top:0;

    .files-added-items {
      padding-right: 0;

      .files-name {
        a {
          color: $dark_blue;
          text-decoration: none;
          margin-right: 8px;
        }
      }

      .remove-file {
        .icons {
          &.delete-regular {
            background-position: 0px -2px;
          }
        }
      }
    }
  }

  .drop-files-block {
    .input-file-block {
      label {
        margin-bottom: 0px;
      }
    }
  }
}
