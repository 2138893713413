.sender-cell-item {
  max-width: 250px;
  width: 100%;
  display: inline-block;
  position: relative;
  span {
    max-width: 250px;
    width: 100%;
    white-space: nowrap;
  }

  .sender-cell-item-tooltip {
    position: absolute;
    left: 0;
    top: 50px;
    padding: 4px 8px;
    background: #ffffff;
    border-radius: 6px;
    box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
    min-width: fit-content;
    white-space: nowrap;
    z-index: 999;
  }
}
.card-block {
  background: $white;
  padding: 32px;
  border-radius: 6px;
  box-shadow: 0 0 6px 2px rgba(51, 60, 72, 0.05);
  margin-bottom: 16px;

  .table {
    tr {
      font-size: 16px;
      td {
        font-size: 16px;
      }
    }
  }

  .add-domain-block {
    position: absolute;
    top: 0;
    right: 0;
  }

  &.company-item-elem {
    .btn.loading:after {
      right: 15px;
    }

    &.show-one-card-block {
      position: relative;
      z-index: 12 !important;
    }

    .company-info-container {
      padding: 0 18px;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }

    .company-info-col-btns {
      width: 20%;
    }
    .company-info-col {
      width: 40%;
    }

    .company-item-actions {
      justify-content: flex-end;
      align-items: flex-start;
      flex-direction: row;
      display: flex;

      .group-actions {
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-start;
        min-width: 220px;
        display: flex;
      }

      .btn {
        width: auto;
        min-width: 200px;
      }

      .company-action-button {
        position: relative;
        min-height: 32px;

        .company-arrow {
          position: absolute;
          right: 2px;
        }
      }
    }
  }

  .no-wrap {
    flex-wrap: nowrap;
  }

  .card-header-item {
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
    display: flex;

    &.orders-table-icon {
      display: inline-block;
      padding-left: 3px;
    }
  }

  .no-info {
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    line-height: 24px;
    display: flex;

    .no-info-title,
    .no-info-link {
      color: $gray_primary;
      padding-right: 16px;
      font-family: $font;
      font-size: 16px;
    }

    .no-info-link {
      cursor: pointer;
      color: $blue;
    }
  }

  .card-no-items {
    .card-no-items-title {
      color: $gray_primary;
      margin-bottom: 15px;
      font-family: $font;
      line-height: 24px;
      padding-top: 1px;
      font-size: 16px;
      display: block;
    }
  }

  &.user-loading {
    min-height: 505px;
  }

  &.notices-loading {
    min-height: 505px;
  }

  &.domain-loading {
    min-height: 505px;
  }

  &.order-loading {
    min-height: 505px;
  }


  &.success-card {
    padding: 27px 32px!important;
    border:1px solid #00BB28;
  }

  &.remove-card{
    padding: 27px 32px!important;
    border:1px solid $orange;
  }

  &.yellow-border {
    border: 1px solid $orange;
  }

  &.step-third-container {
    padding: 64px!important;
  }

  .card-address {
    max-width: 300px;
  }

  .btns-block-notices {
    .btn{
      font-size: 14px;
      line-height: 18px;
      height: auto;
    }
  }

  .btns-block{
    display: flex;
    align-items: center;
    .btn{
      margin-bottom: 0;
      margin-right:16px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
  .btn {
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }

    &.no-margin {
      margin: 0;
    }
  }
  &.card-block-hover:hover {
    box-shadow: 0 2px 4px rgba($dark_blue, .08), 0 6px 8px rgba(#5B6777, .2);
  }
  &:last-child {
    margin-bottom: 0;
  }

  .card-title {
    @extend .h1;
    line-height: 38px;
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    &.domain-company-item {
      margin-bottom: 0;

      .card-id {
        margin-right: 15px;
        margin-left: 48px;
      }
    }

    &.font-regular {
      font-family: $font;
    }

    .icons {
      width: 32px;
      height: 32px;
      background-size: cover;
      margin-right: 16px;
    }
  }



  .card-id {
    line-height: 20px;
    margin-left: 24px;
    background: #F6F8F9;
    border-radius: 3px;
    padding: 2px 5px 2px 5px;
    font-size: 16px;
    font-weight: 400;
    display: inline-block;
    @extend .gray_primary;
    font-family: $font-roboto;

    span {
      font-family: $font-roboto;
      font-weight: 400;
      display: block;
    }
  }

  .order-admin-info {
    font-family: $font;
    margin-left: 16px;
    color: $dark_blue;
    font-weight: 400;
    font-size: 16px;
  }

  .card-title-second {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 25px;
    font-family: $font-semi;
    display: inline-block;
    position: relative;

    &.mb-0 {
      margin-bottom: 0;
    }

    &.mb-9 {
      margin-bottom: 9px;
    }

    &.mb-13 {
      margin-bottom: 13px;
    }

    .icons {
       position: relative;
       top: -2px;
       color: #7F8FA4;
       margin-right: 16px;
    }

    .card-id {
      line-height: 20px;
      margin-left: 16px;
      background: #F6F8F9;
      border-radius: 3px;
      padding: 2px 3px 1px 5px;
      font-size: 14px;
      font-weight: 400;
      @extend .gray_primary;
      font-family: $font-roboto;
    }
  }
  .card-title-third {
    font-size: 24px;
    line-height: 29px;
    font-family: $font;
    .card-id {
      line-height: 20px;
      margin-left: 16px;
      background: #F6F8F9;
      border-radius: 3px;
      padding: 2px 3px 1px 5px;
      font-size: 14px;
      font-weight: 400;
      @extend .gray_primary;
      font-family: $font-roboto;
    }

    &.bold {
      font-family: $font-semi;
    }
  }
  .card-map {
    @extend .gray_primary;
    margin-bottom: 16px;
    &:last-child {
   margin-bottom: 0;
    }
  }
}
