.steps-block {
  margin-bottom: 32px;
  display: flex;

  .step {
    width: 33.333333%;
    margin: 0px 32px 0 0;
    background-position: 0 0;
    background-repeat: no-repeat;
    background-color: $white;
    padding: 17px 0px 17px 32px;
    display: flex;
    align-items: center;
    height: 80px;
    

    .step-shadow {
      height: 80px;
      box-shadow: 0 2px 4px rgba($dark_blue, 0.08);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      background-color: $tr;
      z-index: 0;
    }

    &:last-child {
      margin: 0;
    }

    @media screen and (max-width: 1330px) {

    }

    &.step-completed {
      .step_number {
        border-right: 1px solid $gray_secondary;
      }
    }
    .step_number {
      height: 40px;
      line-height: 40px;
      border-right: 1px solid $gray_secondary;
      padding-right: 17px;
      font-size: 24px;
      color: $dark_blue;
      margin-right: 16px;
    }
    &.step-completed {
      .step_number{
        text-indent: -999em;
        width:48px;
        background: url($static_path + 'img/step-completed-number.svg') 0 50% no-repeat;
      }
    }
    .step_title {
      padding-top: 4px;

      span{
        display: block;
        line-height: 20px;


        &:first-child{
          color: #333c48;
          font-family:$font_semi;
        }
        &:last-child{
          color: #7f8fa4;
          font-size: 14px;
        }
      }
    }

    &:first-child {
      position: relative;
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
      padding-left: 32px;

      &.step-completed {
        background-color: transparent;
        border: 1px solid $gray_secondary;
        padding-left: 30px;
      }

      .step-shadow {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
        right: -1px;
      }

      &.step-active {
        position: relative;

        &:before {
          content:'';
          height: 80px;
          width: 4px;
          background-color: $green;
          position: absolute;
          top: 0;
          left: 0;
          border-top-left-radius: 6px;
          border-bottom-left-radius: 6px;
          z-index: 1;
        }
      }
    }
    &:nth-child(2) {
      padding-left: 47px;
      position: relative;

       &:after {
        content:'';
        width: 53px;
        height: 80px;
        background-image: url($static_path + 'img/step-after-normal.svg');
        position: absolute;
        top: 0px;
        left: -35px;
        z-index: 1;
      }

      .step-shadow {
        left: -14px;
        right: 15px;
      }

      &.step-completed {
        background-color: transparent;
        border: 1px solid $gray_secondary;
        padding-left: 52px;

        &:after {
          background-image: url($static_path + 'img/step-after-completed.svg');
          width: 54px;
          top: -1px;
        }

        .step_number {
          border-right: 1px solid $gray_secondary;
        }
      }

      .step_number {
        border-right: 1px solid $gray_light;
      }

      &.step-active {
        background-position: -9px 0;
        padding-left: 48px;

        &:after {
          background-image: url($static_path + 'img/step-after-active.svg');
          width: 58px;
          top: 0px;
        }
      }
      &.step-completed {
        background-position: 0 2px;
        margin-left: 0;
      }
    }
    &:nth-child(3) {
      padding-left: 31px;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
      position: relative;

      &:after {
        content:'';
        width: 53px;
        height: 80px;
        background-image: url($static_path + 'img/step-after-normal.svg');
        position: absolute;
        top: 0px;
        left: -52px;
      }

      @media screen and (max-width: 1305px) {
        padding-left: 27px;
      }

      .step_number {
        border-right: 1px solid $gray_light;
      }

      .step-shadow {
        left: -33px;
        right: 0px;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
      }

       &.step-completed {
        .step_number {
          border-right: 1px solid $gray_secondary;
        }
      }
      &.step-active {
        &:after {
          background-image: url($static_path + 'img/step-after-active.svg');
          width: 58px;
        }
      }
    }
  }
}

.step-third-block{
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  .thank-you-block{
    width:406px;
    h1 {
      margin-bottom:25px;
      img {
        margin-left: 18px;
        position: relative;
        top: -5px;
      }
    }
    p{
   margin-bottom: 31px;
    }
  }
  .processing-info{
    width:505px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 4px;
    .item{
     border-left:1px solid #efefef;
     padding-left: 30px;
     margin-bottom: 32px;
     padding-right: 32px;
     &:nth-last-child(1){
       text-overflow: ellipsis;
       white-space: nowrap;
       margin-bottom: 0;
       overflow: hidden;
       max-width: 50%;

       .value.email {
         white-space: nowrap;
         overflow: hidden;
         text-overflow: ellipsis;
       }
     }
     &:nth-last-child(2){
       margin-bottom: 0;
     }
     .title{
       font-size:16px;
       line-height: 24px;
       margin-bottom: 8px;
       color: #7f8fa4;
     }
     .value{
       font-size: 24px;
       color: #333c48;
       line-height: 32px;
       margin-bottom: 0;
     }
     &:nth-child(2n+1){
       //width: 301px;
       width: 50%;
     }
     &:nth-child(2n){
       //width: ;
     }
    }
  }
}
