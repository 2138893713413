body {
  padding: 0;
}

.table.no-data {
  border-bottom: 1px solid $gray_light;
  margin-bottom: 0 !important;
}

.table.block-table {
  display: block;

  td, tr, tbody, thead {
    display: block;
  }
}

.table-no-data {
  margin-bottom: 20px;
  padding: 15px 8px;
}

.profiles-img {
  display: flex;
  padding-top: 16px;

  .profile-item {
    width: 40px;
    height: 40px;
    margin-right: -8px;
    position: relative;

    img {
      display: block;
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }

    .add-item {
      display: block;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border: 1px dashed #7F8FA4;
      background-color: $white;
      border-radius: 50%;

      &:before {
        width: 13px;
        height: 1px;
        content: '';
        position: absolute;
        left: calc(50% - 6px);
        top: calc(50% - 1px);
        background: #7F8FA4;
        border-radius: 1px;
      }
      &:after {
        width: 1px;
        height: 13px;
        content: '';
        position: absolute;
        top: calc(50% - 7px);
        left: 50%;
        background: #7F8FA4;
        border-radius: 1px;
      }
    }
  }
}

.empty-img {
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: $gray_primary;
  color: #fff;
  font-family: $font-semi;
  font-size: 14px;
  line-height: 40px;
  text-align: center;
}

.modal-dialog {
  max-width: 734px;
  margin-top: 50px;
  margin-bottom: 50px;
  .modal-content {
    border-radius: 6px;
    padding: 32px;
    background: #fff;
    border: none;
    position: relative;
  }
  .modal-body {
    padding: 0;
  }
  .close {
    position: absolute;
    top: 16px;
    right: 16px;
    color: #CCD2DB;
    opacity: 1 !important;
    &:hover {
   .close-regular {
     background-image: url($static_path + 'img/icons/close-hover.svg');
   }
    }
  }
  .modal-title {
    margin-bottom: 0;
    @extend .h2;
    @extend .font_regular;
    text-align: center;
  }
  .modal-header {
    border: none;
    padding: 0;
    display: block;
    padding-bottom: 32px;
  }
}

.status_not_payed {
  color: $red;
}

.status_processing {
  color: $orange;
  font-family: $font_semi;
  text-transform: capitalize;
}

.status_draft {
  font-family: $font_semi;
  text-transform: capitalize;
  color: $gray_primary;
}

.status {
  &.status_removed {
    color: $red;
    font-family: $font_semi;
    text-transform: capitalize;
  }
}

.status_active,
.status_complete {
  color: $green;
  font-family: $font_semi;
  text-transform: capitalize;
}

.status_success_payed {
  color: $green;
  text-transform: capitalize;
}

.billing-info {
  margin-bottom: 16px;
  &:last-child {
    margin-bottom: 0;
  }
  .billing-item {
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 32px;
    .title {
      width: 100px;
      color: #7F8FA4;
    }
    .value {
      flex: 1;
      color: #333C48;
    }
  }
}

.files-added-title {
  font-size: 16px;
  line-height: 24px;
  color: $gray_primary;
  font-family: $font;
  padding-left: 8px;
  margin-bottom: 0;
}

.edit-btn {
  color: $blue;
}

.block {
  padding-top: 24px;
  display: none;

  &.block-open {
    display: block;
  }
}

.billing_details {
  .billing_details_title {
    color: $gray_primary;
    display: block;
    margin-bottom: 16px;
  }
  address {
  span {
   line-height: 24px;
   font-size: 16px;
   color: $dark_blue;
   display: block;
    }
  }
}

.billing_details {
  > span {
    display: block;
    &.billing_price {
   margin-bottom: 4px;
    }
  }
}

.payment_methods {
  margin-bottom: 16px;
}

.payment_method_item {
  padding-bottom: 8px;
  display: flex;
}

.payment_number {
  display: inline-block;
  margin-left: 8px;
  margin-right: 16px;
}

.link-counter {
  line-height: 20px;
  background: #F6F8F9;
  border-radius: 3px;
  padding: 2px 5px 2px 5px;
  font-size: 16px;
  font-weight: 400;
  @extend .gray_primary;
  font-family: $font-roboto;
  min-width: 29px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.split-element {
  position: absolute;
  right: 8px;
  top: 2px;
  color: $gray_light;
}

.notification-status-secondary,
.notification-status {
  position: absolute;
  left: calc(100% + 17px);
  border-radius: 3px;
  padding: 5px 8px 3px;
  white-space: nowrap;
  color: #fff;
  line-height: 16px;
  font-size: 16px;
  font-family: $font-semi;
  background: $gray_primary;
  top: 0;
  &.status-new {
    background: $orange;
  }
  &.status-remove {
    background: $red;

    &.pink {
      background: $pink;
    }
  }
  &.noti-new {
    background: $blue;
  }
}

.notification-status-secondary {
  background: $gray_secondary;
}

.subscription-block {
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 16px;
}

.billing-info {
  margin-bottom: 16px;
}

.link-count-item {
  min-width: 29px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.registered-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  .card-id {
    margin-left: 0;
    margin-right: 24px;
  }

  .registered-block {
    padding-top: 0;
    margin-bottom: 0;

    .registered-block {
      padding-top: 3px;
    }
  }
}

.registered-block {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 16px;
  padding-top: 7px;

  .company-status {
    font-family: $font-semi;
    font-size: 16px;
  }

  &.status_processing {
    color: $orange;
  }

  &.completed {
    @extend .green;
  }

  &.failed {
    @extend .red;
  }

  .icons {
    margin-right: 8px;
    position: relative;
    top: -1px;
  }
}



.registration-id-info {
  padding-top: 12px;
  width: 369px;

  .title {
    display: block;
    // margin-bottom: 16px;
    line-height: 24px;
  }

  .value {
    font-size: 14px;
    line-height: 20px;
    display: inline-block;
    color: #7F8FA4;
    font-family: $font-roboto;
    font-weight: 400;
  }
}

// .registered-id-title {
//   margin-bottom: 16px;
// }

.registered-id {
  @extend .gray_primary;
  position: relative;
  font-family: $font-roboto;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  cursor: pointer;

  &.second-type {
    align-self: flex-start;
    font-family: $font;
    line-height: 32px;
    color: $dark_blue;
    font-size: 16px;
    display: inline;
    min-height: 32px;

    &.company-last-update {
      font-family: "ProximaNova-Regular";
      color: #7F8FA4;
    }
  }

  &.company-last-update {
    .copy-help {
      min-width: 150px;
      top: -65px;

      p {
        font-size: 18px;
        line-height: 20px;
        color: #fff;
        margin-bottom: 8px;
      }
    }

    .auto-renew-bold {
      font-weight: 600;
    }
  }

  .copy-help {
    position: absolute;
    display: block;
    padding: 7px 11px 7px 12px;
    font-size: 12px;
    line-height: 14px;
    left: calc(100% + 13px);
    border-radius: 3px;
    top: -4px;
    background: $blue;
    color: $white;
    opacity: 0;
    visibility: hidden;
    font-family: $font-semi;

    &:after {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 3.5px 3px 3.5px 0;
      border-color: transparent $blue transparent transparent;
      left: -3px;
      top: calc(50% - 3.5px);
    }
  }
  &:hover {
    color: $blue;
    text-decoration: none;

    .copy-help {
       opacity: 1;
       visibility: visible;
    }
  }
}

.font_regular {
  font-family: $font;
}

b,
strong,
.font_bold {
  font-family: $font-semi;
}

.block_typography {
  margin-bottom: 90px;
  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5,
  h6,
  .h6,
  p {
    margin: 0;
  }
}

.small-img-placeholder {
  display: inline-block !important;
}

.table-title {
  margin-bottom: 16px;
  font-weight: normal;
  color: $dark_blue;
  line-height: 24px;
}

.table-link{
  font-family:$font-semi;
  font-size:16px;
  line-height: 24px;
  color: $blue;
  text-decoration: underline;
  &:hover{
    color: $blue_hover;
  }
}

.table {
  margin-bottom: 16px;
  border-collapse: collapse;
  // font-family: $font;
  &:last-child {
    margin-bottom: 0;
  }

  &.table-mb-25 {
    margin-bottom: 25px;
  }

  &.user-table {
    th {
      &:nth-child(3) {
        padding-left: 43px;
      }
    }
  }
  td,
  th {
    padding: 9px 8px 6px;
    font-family: $font !important;
    line-height: 24px;
    vertical-align: middle;
    font-weight: normal;
    &.deleted {
      color: $red;
      text-decoration: line-through;
    }

    span {
      &.deleted {
        color: $red;
        text-decoration: line-through;
      }
    }
    &.text-right{
      text-align: right;
    }
  }
  thead th {
    border-bottom: none;
    border-top: none;
    color: $gray_primary;
    padding-top: 0px;
    padding-bottom: 4px;
  }
  td {
    border-top-color: $gray_light;
  }
  .btn {
    margin-top: -6px;
    margin-bottom: -2px !important;
  }
  .small-img {
    position: relative;
    top: -2px;
    margin-left: -1px;
    margin-right: 12px;
    text-transform: uppercase;
    font-size: 10px;

    &.email {
      line-height: 26px;
      font-size: 15px;
    }
  }

  .btn-icon {
    display: block;
    float: right;
  }
  .td-check {
    width: 40px;
  }
  .w-100 {
    width: 250px !important;
  }
  .w-200 {
    width: 200px;
  }
  .td-details {
    width: 80px;
  }
  .w-300 {
    width: 300px;
  }
  &.table-hover {
    tr.is_busy_domain {
      transition-duration: 0s;
      animation: blink-animation 1s steps(5, start) infinite;
      -webkit-animation: blink-animation 1s steps(5, start) infinite;
    }

    tbody tr:hover {
      background: $gray_light_v2;
      cursor: pointer;
     .btn-icon {
       color: $dark_blue;
       .btn-icon-text {
        display: block;
        color: $dark_blue;
       }
       .open-in-new {
        @extend .open-in-new-hover;
       }

       .delete-regular {
         @extend .delete-hover;
       }
     }
    }

    &.no-hand-cursor tbody tr:hover {
      background: transparent;
      cursor: default;
    }
  }
}

@keyframes blink-animation {
  to {
    background-color: $gray_light_v2;
  }
}
@-webkit-keyframes blink-animation {
  to {
    background-color: $gray_light_v2;
  }
}

.bordered-row {
  border-bottom: 1px solid $gray_light;
  padding-bottom: 24px;
  margin-bottom: 24px;
  &:last-child {
    margin-bottom: 0;
  }
}

.small-img {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: inline-block;
  margin: 0 11px 0 0;
  vertical-align: middle;
  background: $gray_primary;
  color: #fff;
  font-size: 12px;
  font-family: $font_semi;
  text-align: center;
}

.billing_email {
  display: block;
  margin-bottom: 8px;
}


.block.add-url{
  > .d-flex{
    display: flex;
    align-items: flex-end;
    .form-group{
   width:586px;
   margin-right: 24px;
   margin-bottom: 0;
    }
  }
}

.block.add-note{
  textarea.form-control{
    resize: none;
    height: 140px;
  }
}

.open-btn {
  &:hover {
    .open-in-new {
      background-image: url($static_path + 'img/icons/open-in-new-hover.svg');
    }
    .delete-regular {
      background-image: url($static_path + 'img/icons/delete-hover.svg');
    }
  }

  &.loading {
    position: relative;

    &:after {
      border-color: $gray_primary $gray_primary $gray_primary transparent;
      -webkit-animation:ld-spin 1s infinite;
      animation:ld-spin 1s infinite;
      border-style: solid;
      border-radius: 50%;
      position: absolute;
      border-width: 3px;
      margin-top: -8px;
      display: block;
      height: 16px;
      width: 16px;
      content: '';
      right: 4px;
      top: 50%;
    }

    .icons {
      opacity: 0;
    }
  }
}

.btn-icon {
  &:hover {
    .open-in-new {
      background-image: url($static_path + 'img/icons/open-in-new-hover.svg');
    }
    .delete-regular {
      background-image: url($static_path + 'img/icons/delete-hover.svg');
    }

    .download {
      background-image: url($static_path + 'img/icons/download-hover.svg');
    }

    .open-in-new {
      background-image: url($static_path + 'img/icons/open-in-new-hover.svg');
    }
  }
}

.remove-file {
  &:hover {
    .download {
      background-image: url($static_path + 'img/icons/download-hover.svg');
    }

    .open-in-new {
      background-image: url($static_path + 'img/icons/open-in-new-hover.svg');
    }
  }
}

.pagination {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: stretch;
  justify-content: center;
  .page-item {
    margin-right: 8px;
  }
  .page-link {
    border: 1px solid $gray_primary;
    border-radius: 3px;
    padding: 6px 4px 4px;
    text-align: center;
    font-size: 14px;
    line-height: 20px;
    width: 32px;
    vertical-align: middle;
    height: 32px;
    font-family: $font-semi;
    font-weight: normal;
    color: $gray_primary;
    &.active,
    &:hover {
   color: $white;
   background-color: $dark_blue;
   border-color: $dark_blue;
    }
    &.btn-back {
   .icons {
     font-size: 14px;
     display: block;
     position: relative;
     top: -3px;
   }
    }

    &.btn-forward {
   .icons {
     font-size: 14px;
     position: relative;
     top: -3px;
     display: block;
   }
    }
  }
}


.tag-new {
  font-size: 12px;
  line-height: 16px;
  color: $blue_lighen;
  display: inline-block;
  padding: 2px 8px 0px 7px;
  margin-left: 16px;
  border-radius: 12px;
  position: relative;
  top: -2px;
  border: 1px solid $blue_lighen;
  font-family: $font-semi;
}

.order-status{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .status{
    display: block;
    margin-left: 16px;
  }
}

.order-status-title{
  @extend .gray_primary;
  @extend .font_regular;
  margin-bottom: 8px;
}

.order-item{
  margin-bottom: 25px;

  &:last-child {
    margin-bottom: 0;
  }

  .small-img {
    margin: 0 8px 0 0;
  }
}

.user-link {
  color: black !important;

  span {
    color: black !important;
  }
}

.order-status-value{
  @extend .font_regular;
  line-height: 32px;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .order-status-link {
    color: $dark_blue;
  }

  .image-block {
    margin-right: 16px;
    width: 40px;
    height: 40px;
    display: block;
  }

  img{
    display: block;
    margin-right: 16px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
}

.tag-pending-invitation {
  font-size: 16px;
  line-height: 17px;
  color: $orange;
  display: inline-block;
  padding: 2px 7px 0px;
  padding-bottom: 2px;
  border-radius: 12px;
  border: 1px solid $orange;
  position: relative;
  // top: -2px;
  font-family: $font-semi;
}

.tag-edit {
  font-size: 12px;
  line-height: 17px;
  color: $orange;
  display: inline-block;
  padding: 1px 7px 0px;
  margin-left: 17px;
  border-radius: 12px;
  border: 1px solid $orange;
  position: relative;
  top: -2px;
  font-family: $font-semi;
}

.invoice-info{
  .invoice-item{
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .order-status{
      width: auto!important;
    }

    .status {
      margin-left: 0;
    }

    .card-id {
      margin-left: 0;
    }

    div {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: pre;
    }

    .title{
     width: 100%;
     max-width:150px;
     margin-right: 16px;
     font-size:16px;
     line-height: 32px;
     color: #7f8fa4;
     display: block;
    }
    .value{
      display: block;
      line-height: 32px;
      font-size: 16px;
      color: #333c48;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: pre;

     a {
      color: #333c48;
     }

      &.orange {
        color: $orange;
      }

      &.green {
        color: $green;
      }

     &.red {
      color: $red;
     }
    }
  }
}

.removed-item {
  line-height: 24px;
  cursor: pointer;
  text-decoration: line-through;
  position: relative;

  .copy-help {
    position: absolute;
    display: block;
    padding: 7px 11px 7px 12px;
    font-size: 12px;
    line-height: 14px;
    left: calc(100% + 13px);
    border-radius: 3px;
    top: -4px;
    background: $blue;
    color: $white;
    opacity: 0;
    visibility: hidden;
    font-family: $font-semi;

    &:after {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 3.5px 3px 3.5px 0;
      border-color: transparent $blue transparent transparent;
      left: -3px;
      top: calc(50% - 3.5px);
    }
  }
  &:hover {
    color: $red;
    text-decoration: none;
    .copy-help {
      opacity: 1;
      visibility: visible;
    }
  }
}

.logo {
  width: 139px;
  height: 60px;
  margin: 0 auto 32px;
  display: block;
  img {
    display: block;
    margin: 0;
  }
}

.tag-delete {
  font-size: 12px;
  line-height: 12px;
  color: $red;
  display: inline-block;
  padding: 3px 8px;
  margin-left: 16px;
  border-radius: 12px;
  border: 1px solid $red;
  position: relative;
  top: -2px;
  font-family: $font-semi;
}

.tag-registered {
  font-size: 12px;
  line-height: 12px;
  color: $gray_primary;
  display: inline-block;
  padding: 3px 8px;
  margin-left: 16px;
  border-radius: 12px;
  border: 1px solid $gray_primary;
  position: relative;
  top: -2px;
  font-family: $font-semi;
}

.upload-domain-error{
  display: block;
  padding-top: 6px;
  color: $gray_primary;
  font-size: 13px;
}

.disabled {
  .checkbox {
    label {
      cursor: default;

      &:hover {
        color: $gray_secondary;

        .checkmark-regular {
          background-image: url($static_path + 'img/icons/checkmark-regular.svg');
          background-position: 0 -3px;
        }
      }
    }
  }
}

.checkbox {
  position: relative;

  label {
    color: $gray_secondary;
    font-size: 14px;
    cursor: pointer;
    display: block;
    margin: 0;
    line-height: 18px;

    .checkmark-regular {
      margin-right: 8px;

      &.orange {
        margin-right: 16px;
      }
    }

    &:hover {
     color: #333C48;

     .checkmark-regular {
       background-image: url($static_path + 'img/icons/checkmark-hover.svg');
     }
    }
  }

  input {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    left: 0;
    top: 0;
    width: 24px;
    height: 24px;
    &:checked + label {
     color: $gray_primary;
     .checkmark-regular {
       background-image: url($static_path + 'img/icons/checkmark-filled.svg');

       &.orange {
        background-image: url($static_path + 'img/icons/checkmark-filled-orange.svg');
       }

       &.red {
        background-image: url($static_path + 'img/icons/checkmark-filled-red.svg');
        background-position: 0 0;
       }
     }
    }
  }

  &.checked {
    label {
      color: $gray_primary;
      .checkmark-regular {
        background-image: url($static_path + 'img/icons/checkmark-filled.svg');

        &.orange {
          background-image: url($static_path + 'img/icons/checkmark-filled-orange.svg');
        }

        &.red {
          background-image: url($static_path + 'img/icons/checkmark-filled-red.svg');
          background-position: 0 0;
        }
      }
    }
  }
}

.wrapper {
  position: relative;
  min-height: calc(100vh - 15px);

  .body-section {
    width: 1482px;
    padding: 32px 32px 32px 0;
    margin: 0 auto;
    padding-left: 280px;

    @media screen and (max-width: 1497px) and (min-width: 1280px) {
      max-width: 1482px;
      width: 100%;
    }

    @media screen and (max-width: 1279px) {
      width: 1278px;
    }

    > section,
    .banners-list {
      z-index: 2;
      width: 100%;
      /* add class to section for registration show first card*/
      &.show-one-card-block {
        position: relative;
        &:after {
          content:'';
          width: 100%;
          height: 100%;
          background-color: rgba($gray_light_v2 ,0.6);
          position: absolute;
          top: 0;
          left: 0;
          z-index: 10;
        }

        .card-block {
          &:nth-child(1) {
            z-index: 5;
            position: relative;
          }
        }
      }
    }
  }
}

.body-section {

}

.card-block {
  padding: 24px 12px !important;
  + .btn {
    margin-top: 32px;
  }

  &.payment-introduction-card {
    padding: 32px 40px !important;
  }
  &.card-bordered-orange {
    border: 1px solid #f7981c;
    padding: 31px 15px !important;
    box-shadow: 0 2px 4px 0 rgba(51, 60, 72, 0.08);
  }

  .btn-control {
    .btn {
      margin-bottom: 0;
      margin-right: 16px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &.disabled-card {
    position: relative;

    &:after {
      background-color: rgba(246, 248, 249, .6);
      position: absolute;
      display: block;
      content: '';
      bottom: 0;
      right: 0;
      top: 0;
      left:0;
    }
  }
}

.card-block [class^="col-"] {
  &:first-child {
    padding-left: 31px;
  }
  &:last-child {
    padding-right: 31px;
  }
  & [class^="col-"] {
    &:first-child {
   padding-left: 16px;
    }
    &:last-child {
   padding-right: 16px;
    }
  }
}

.select2-container {
  width: 100% !important;
  display: block;
  .select2-selection--single {
    height: 40px;
    .select2-selection__rendered {
   line-height: 24px;
   padding-top: 8px;
   padding-bottom: 8px;
   font-size: 16px;
   height: 40px;
   font-family:$font;
   padding-left: 16px;
    }
  }
}

.select2-container--above {
  & ~ .select2-container {
    transform: translateY(1px);
  }
}

.select-sm {
  .select2-container {
    width: 100% !important;
    display: block;
    .select2-selection--single {
   height: 32px;
   .select2-selection__rendered {
     line-height: 16px;
     height: 32px;
     padding-left: 16px;
     font-family: $font;
     color: #7f8fa4;
     font-size: 14px;
   }
    }
  }
  .select2-container--default .select2-selection--single .select2-selection__arrow {
    top: 4px;
  }
}

.select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple, .select2-container--default.select2-container--open.select2-container--below .select2-selection--single {
  border-bottom: none;
}

.select2-results__option:last-child {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  width: 24px;
  height: 24px;
  display: block;
  top: 7px;
  background: url($static_path + 'img/icons/dropdown-down.svg') 50% 50% no-repeat;
  right: 7px;
  b {
    display: none;
  }
}

.select2-container--open .select2-selection--single .select2-selection__arrow {
  transform: scale(-1);
}

.select2-container--default .select2-selection--single {
  border: 1px solid #ccd2db;
  border-radius: 3px;
  background: #fff;
  width: 100%;
  display: block;
  box-shadow: inset 0 1px 2px rgba(51,60,72,.1);
  &[aria-expanded="true"] {
    box-shadow: none;
  }
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background: #fff;
  font-family: $font;
  color: #333C48;
}

.select2-results__option {
  font-family: $font;
  color: #7f8fa4;
  padding-left: 16px;
  padding-right: 16px;
  font-size: 16px;
  line-height: 24px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.select2-container--default .select2-results__option[aria-selected=true] {
  background: #F6F8F9;
  color: #333C48;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #333C48;
}

.select2-container--default.select2-container--disabled .select2-selection--single {
  background: #F6F8F9;
  border-color: #F6F8F9;
  box-shadow: none;
  .select2-selection__arrow {
    display: none;
  }
}

.select2-dropdown {
  border-color: #ccd2db;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-8 {
  margin-bottom: 8px !important;
}

.tooltip-block {
  position: relative;
  display: inline-block;

  &.form-icon {
    align-items: center;
    display: flex;
    height: 40px;
  }

  .tooltip-popup {
    position: absolute;
    left: calc(50% - 169px);
    bottom: calc(100% + 13px);
    border-radius: 3px;
    width: 321px;
    background: #333C48;
    padding: 12px;
    opacity: 0;
    visibility: hidden;
    transition: opacity .2s, visibility .2s;
    &:after {
   content: '';
   position: absolute;
   top: 100%;
   left: calc(50% - 4.5px);
   width: 0;
   height: 0;
   border-style: solid;
   border-width: 5px 4.5px 0 4.5px;
   border-color: #333C48 transparent transparent transparent;
    }
    p {
   font-size: 12px;
   line-height: 14px;
   margin-bottom: 16px;
   color: #fff;
   &:last-child {
     margin-bottom: 0;
   }
    }
  }
  &.active {
    .tooltip-popup {
   opacity: 1;
   visibility: visible;
    }
    .btn-icon {
   color: #333C48;
    }
    .tooltip-regular {
   background-image: url($static_path + 'img/icons/tooltip-hover.svg');
    }
  }
  .tooltip-title {
    color: #fff;
    font-size: 12px;
    text-transform: uppercase;
    font-family: $font-semi;
    line-height: 14px;
    margin-bottom: 8px;
  }
}

.company-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .card-title-second {
    margin-bottom: 0;
  }
}

.arrow-drop-btn {
  white-space: nowrap;
  font-family: $font_semi;
  font-size: 14px;
  color: #7f8fa4!important;
  .icons {
    margin-left: 16px;
    position: relative;
    top: -2px;
  }
  &:hover {
    text-decoration: none;
    color: #7f8fa4;
  }
  &.active {
    .dropdown-down {
   background-image: url($static_path + 'img/icons/dropdown-up.svg');
    }
  }
}

.send-btn {
  padding: 6px 12px;
}

.add-new-domain-block {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  .form-group {
    width: 429px;
    &:last-child {
   width: 184px;
    }
    .btn {
   width: 100%;
    }
  }
}

.btn-control-table {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 16px;
  .btn {
    margin-bottom: 0;
    margin-right: 16px;
  }
}

.confirm-remove-btn {
  margin-bottom: 0 !important;
  &.active {
    display: none;
  }
}

.confirm-remove-block {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  &.active {
    display: flex;
  }
  p {
    margin-bottom: 0;
    margin-right: 32px;
  }
  .form-group {
    width: 160px;
    margin-bottom: 0;
    margin-right: 8px;
  }
}

.order-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 27px;
  margin-bottom: 24px;
  border-bottom: 1px solid #efefef;
  .card-title-third {
    margin-bottom: 0;
  }

  .icons {
    margin-right: 0;
    margin-left: 16px;

    &.download-hover {
      margin-right: 0;
    }
  }
}

.order-title {
  line-height: 24px;
  position: relative;
  font-weight: 400;
  .notification-status:first-child{
    position: static;
    margin-right: 8px;
    display: inline-block;
  }
}

.order-small-title{
  display: block;
  font-size: 16px;
  color: #7f8fa4;
  margin-bottom: 7px;
}

.comapany-name{
  font-size: 16px;
  line-height: 24px;
}

.comapany-name-old{
  font-size: 16px;
  line-height: 24px;
  color: #7f8fa4;
  text-decoration: line-through;
}

.order-price-title{
  font-size: 16px;
  color: #7f8fa4;
  margin-bottom:8px;
}

.order-price-value{
  font-family:$font-semi;
  font-size: 32px;
  line-height: 38px;
  color: #333c48;
}

.status, .label {
  background-color: rgba($orange, 0.1);
  font-family: $font_semi;
  white-space: nowrap;
  text-transform: uppercase;
  border-radius: 3px;
  font-size: 14px;
  line-height: 20px;
  padding-top: 3px;
  padding-bottom: 1px;
  display: inline-block;
  padding-left: 8px;
  padding-right: 8px;
  font-family:$font-semi;
  color: $orange;

  &.removed {
    text-transform: capitalize;
    background-color: rgba($red, 0.1);
    color: $red;
  }

  &.sending {
    text-transform: capitalize;
    background-color: rgba($orange, 0.1);
    color: $orange;
  }

  &.processing {
    text-transform: capitalize;
    background-color: rgba($orange, 0.1);
    color: $orange;

    &.notice {
      color: $blue;
      background-color: rgba($blue, 0.1);
    }
  }

  &.draft {
    text-transform: capitalize;
    background-color: rgba($gray_primary, 0.1);
    color: $gray_primary;
  }

  &.completed,
  &.complete,
  &.registred,
  &.status-completed,
  &.label-success {
    text-transform: capitalize;
    background-color: rgba($green, 0.1);
    color: $green;
  }
}
