// tw settings
@tailwind base;
@tailwind components;
@tailwind utilities;
// Variables
@import "base/variables";

// Vendors
@import "./node_modules/bootstrap-scss/bootstrap";

// Base
@import "base/fonts";
@import "base/mixins/all";
@import "base/reset";
@import "base/utils-clases";
@import "base/extends";
@import "base/animations";
@import "base/buttons";
@import "base/form";

$static_path: '../';
// // Components
@import "components/all";

// Modals
@import "components/modals/all";

// Containers
@import "containers/all";
