$black: #33363A;
$dark_blue: #333C48;
$gray_primary: #7F8FA4;
$gray_secondary: #CCD2DB;
$gray_light: #EFEFEF;
$gray_light_v2: #F6F8F9;
$white: #fff;
$orange: #F7981C;
$orange_hover: #E88B11;
$orange_lighen: #FCC93C;
$blue: #1991EB;
$blue_hover: #0F7DD1;
$blue_lighen: #3BA1ED;
$blue_5b: #5B6779;
$blue_0c: #0C80D6;
$green: #00BB28;
$green_hover: #00A122;
$green_lighen: #59DD75;
$red: #EF4836;
$pink: #f9b6af;
$orange_fb: #FDB515;
$gray_5b: #5B6777;
$gray_fb: #FBFBFB;
$dark_33: #333C48;
$gray_e8: #E8E8E8;
$gray_78: #78808B;
$gray_f0: #f0f0f0;

$tr: transparent;

$font: 'ProximaNova-Regular';
$font-semi: 'ProximaNova-Semibold';
$font-bold: 'ProximaNova-Bold';
$font-roboto: 'roboto_mono';


$h1-font-size: 32px;
$h2-font-size: 24px;
$h3-font-size: 18px;
$h5-font-size: 16px;
$h6-font-size: 14px;

$static_path: './';

$btn-font-weight: 600;

:focus{
	outline:none !important;
}


