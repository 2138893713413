.payment-method-container-item {
  background-color: $white;
  box-shadow: 0 0 6px 2px rgba($dark_blue, 0.05);
  border-radius: 6px;
  padding: 34px 40px 40px 40px;

  .title {
    font-size: 24px;
    line-height: 29px;
    font-family: $font-semi;
    color: $dark_blue;
    display: block;
    margin-bottom: 37px;
  }

  .img-block {
    margin-bottom: 42px;
    img {
      display: block;
    }
  }

  .content {
    margin-bottom: 31px;
    p {
      margin: 0 0 24px 0;
      font-size: 16px;
      line-height: 24px;
      font-family: $font;
      color: $dark_blue;

      &:last-child {
        margin: 0;
      }

      a {
        font-size: 16px;
        line-height: 24px;
        font-family: $font;
        color: $blue;
      }
    }
  }
}