.company-members-scroll {
  overflow-x: hidden;
  overflow-y: auto;
  height: 358px;

  /* width */
  &::-webkit-scrollbar {
    width: 6px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    transition: background-color ease 0.2s;
    background-color: transparent;
    border-radius: 4px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    transition: background-color ease 0.2s;
    background-color: transparent;
    border-radius: 4px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: darken($gray_secondary, 10);
  }

  &:hover {
    &::-webkit-scrollbar-track {
      background-color: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $gray_secondary;
    }
  }
}


.react-modal {
  &.modal-edit-member {
    width: 734px;
    background-color: $white;
    border-radius: 6px;
    padding: 32px;
    box-shadow: 0 2px 4px rgba($dark_blue, 0.08);
    position: relative;
  }
}

.modal-edit-member-body {
  .close-edit-member {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
  }

  .edit-member-title {
    font-size: 24px;
    line-height: 32px;
    margin: 0 0 28px 0;
    color: $dark_blue;
    font-family: $font;
    text-align: center;
  }

  .member-block {
    margin-bottom: 33px;
    min-height: 358px;

    &.no-members {
      justify-content:center;
      align-items:center;
      display: flex;
    }
  }

  .member-item {
    display: flex;
    border-top: 1px solid $gray_light;
    padding: 23px 15px;

    &.current-user {
      .react-select__indicators {
        visibility: hidden;
      }

      &.is-main {
        .member-manage-block span.btn-icon {
          display: none;
        }
      }
    }

    &:last-child {
      border-bottom: 1px solid $gray_light;
    }

    .member-info {
      display: flex;
      align-items: center;
      flex: 1;

      .member-img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-right: 16px;

        &.no-member-img {
          .has-img {
            display: none;
          }

          .no-member-img-block {
            display: flex;
          }
        }

        .has-img {
          width: 100%;
          display: block;
          border-radius: 50%;
        }

        .no-member-img-block {
          background-color: $gray_primary;
          display: flex;
          align-items: center;
          justify-content: center;
          display: none;
          width: 100%;
          height: 100%;
          border-radius: 50%;

          span {
            display: block;
            font-size: 14px;
            line-height: 17px;
            font-family: $font-semi;
            color: $white;
            text-transform: uppercase;
          }
        }
      }

      .member-name {
        padding-top: 4px;

        span {
          display: block;

          span {
            display: inline-block;
          }
        }

        a.invitation-resend {
          margin-left: 10px;
          color: #1991EB;
        }

        .name {
          font-size: 16px;
          line-height: 19px;
          font-family: $font-semi;
          color: $dark_blue;
        }

        span.invitation-status {
          display: inline-block;  
        }

        .email {
          font-size: 16px;
          line-height: 19px;
          color: $dark_blue;
        }
      }
    }

    .member-manage-block {
      width: 256px;
      display: flex;
      align-items: center;

      &.invitation-status-active {
        .invitation-status {
          display: inline-block;
        }
        .form-group {
          display: none;
        }
      }

      .filter-input-block {
        width: 160px;
      }

      .form-group {
        width: 160px;

        .open-dropdown-menu {
          width: 34px;
        }
      }

      .info-icon {
        margin-left: 32px;
        margin-right: 16px;

        &:hover {
          .tooltip-popup {
            opacity: 1;
            visibility: visible;
          }
        }
      }

      .invitation-status {
        display: none;
        font-size: 16px;
        line-height: 16px;
        font-family: $font-semi;

        &.orange {
          color: $orange;
        }

        &.red {
          color: $red;
        }

        &.green {
          color: $green;
        }
      }
    }

    .tooltip-block {
      position: relative;
      display: inline-block;

      .tooltip-popup {
        position: absolute;
        left: calc(50% - 161px);
        bottom: calc(100% + 13px);
        border-radius: 3px;
        width: 321px;
        background: #333C48;
        padding: 12px;
        opacity: 0;
        visibility: hidden;
        transition: opacity .2s, visibility .2s;

        &:after {
         content: '';
         position: absolute;
         top: 100%;
         left: calc(50% - 4.5px);
         width: 0;
         height: 0;
         border-style: solid;
         border-width: 5px 4.5px 0 4.5px;
         border-color: #333C48 transparent transparent transparent;
        }
        p {
         font-size: 12px;
         line-height: 14px;
         margin-bottom: 16px;
         color: $white;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      &.active {
        .tooltip-popup {
          opacity: 1;
          visibility: visible;
        }
        .btn-icon {
          color: #333C48;
        }
        .tooltip-regular {
       background-image: url('../../img/icons/tooltip-hover.svg');
        }
      }
      .tooltip-title {
        color: #fff;
        font-size: 12px;
        text-transform: uppercase;
        font-family: $font-semi;
        line-height: 14px;
        margin-bottom: 8px;
      }
    }
  }
}
