.add-event-block {
  display: flex;
  border-bottom: 1px solid $gray_light;
  padding-top: 33px;
  padding-bottom: 32px;
  
  &:last-child {
    border-bottom: 0;
    padding-bottom: 0;
  }

  .card-title-third {
    line-height: 32px!important;
  }

  .add-event-item-left {
    width: 368px;

    .card-title-third {
      line-height: 32px;
    }
  }

  .add-event-item-right {
    width: 738px;
  }
}