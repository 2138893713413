.associated-container {
  min-height: 304px;

  &.loading-container {
    &:before {
      margin-left: -20px;
      margin-top: -20px;
      height: 40px;
      width: 40px;
      top: 64%;
    }

    &:after {
      display: none;
    }

    .no-data-container  {
      opacity: 0;
    }
  }

  .no-data-container {
    padding: 20px 0;

    &.users {
      min-height: 0;

      .icons.users-not-found-icon {
        margin-bottom: 15px;
      }
    }
    

    .no-data-content {
      p {
        margin-bottom: 0;
      }
    }
  }
}

.associated-company {
  margin-bottom: 24px;
  padding-bottom: 24px;
  position: relative;

  &:after {
    background-color: #EFEFEF;
    position: absolute;
    display: block;
    content: '';
    height: 1px;
    right: 31px;
    left: 31px;
    bottom: 0;
  }

  &:last-child {
    padding-bottom: 0;
    margin-bottom: 0;

    &:after {
      display: none;
    }
  }
}