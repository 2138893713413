.drop-files-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $gray_light_v2;
  padding: 24px 32px 24px 32px;
  border-radius: 3px;
  position: relative;

  .loading {
    position: absolute;
    right: 120px;
    top: 37px;
  }

  .title-block {
    display: block;
    font-size: 16px;
    line-height: 24px;
    color: $gray_primary;
    display: flex;
    align-items: center;

    span {
      display: block;
    }
  }

  .icons {
    margin-right: 16px;
  }
}

.input-file-block {
  position: relative;

  input[type="file"] {
    display: none;
  }
}

.files-added-block-list {
  width: 100%;
}

.files-added-block {
  padding-top: 19px;

  .files-added-title {
    border-bottom: 1px solid $gray_light;
    padding-bottom: 4px;
  }

  .files-added-items {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $gray_light;
    padding: 9px 8px 6px;

    &:last-child {
      border-bottom: none;
    }

    .files-name {
      font-family: $font-semi;
      align-items: center;
      line-height: 24px;
      font-size: 16px;
      display: flex;
      flex: 1;
      width: 100%;
      overflow: hidden;

      a {
        display: block;
        font-family: $font-semi;
        font-size: 16px;
        color: $blue;
        text-decoration: none;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: pre;
        font-family: "ProximaNova-Regular";

        &:hover {
          text-decoration: underline;
          .icons {
            &.open-in-new {
              background-image: url('../img/icons/open-in-new-hover.svg');
            }
          }
        }
      }

      .icons {
        margin-left: 9px;
      }
    }
  }

  .add-delete-icon {
    display: flex;
    align-items: center;
    width: 60px;
    justify-content: space-between;

    span {
      display: block;
      cursor: pointer;
    }

    .icons{
      &.open-in-new {
        background-position: 0 0px;
      }
      &.filters-exist {
        background-position: 0 0px;
      }
    }
  }
}
.hide-domain-upload{
  .drop-files-block{
    transition: 1s;
    display: none;
  }
}