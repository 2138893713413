.payment-method-item {
  background-color: $white;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba($dark_blue, 0.08);
  width: 100%;
  height: 80px;
  margin-bottom: 8px;
  border: 2px solid $tr;
  display: flex;
  align-items: center;
  padding: 20px 30px;

  &:hover {
    cursor: pointer;
  }

  &.disabled {
    opacity: 0.5;
  }

  &.selected {
    border: 2px solid $green;
  }

  &:last-child {
    margin-bottom: 0;
  }

  .img-block {
    max-width: 220px;
    width: 100%;
    img {
      display: block;
    }
  }

  .description {
    font-size: 16px;
    line-height: 24px;
    color: $dark_blue;
    font-family: $font;
    display: block;
    padding-top: 2px;
  }
}