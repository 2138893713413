.table.no-padding {
  td {
    //padding: 9px 8px 6px;
    padding: 0;
  }

  .row-item {
    padding: 9px 8px 6px;
  }
}
