.registration-popover {
  margin-right: 60px;
  margin-top: 60px;
}

.add-registration-input-form {
  margin-top: -2px;
  display: none;
  width: 369px;

  &.show-registration-form {
    display: block;
  }

  .btn-control {
    padding-top: 7px;

    .btn {
      &:nth-child(2) {
        padding: 8px 48px 8px 47px;
      }
    }
  }
}

.open-register-form {
  &.hidden {
    display: none;
  }
}
